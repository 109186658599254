<template>
  <div>
    <div class="title">
      แก้ {{khomun.chuetarang}}
      <div v-if="['anime','khondang','seiyuu'].includes(khomun.chuetarang)">
        <a :href="'/'+khomun.chuetarang+'/'+encodeURIComponent(khomun.khapraki)" target="_blank">{{khomun.khapraki}}</a>
      </div>
      <div v-if="khomun.chuetarang=='game'">
        <a :href="'/zenigame/'+encodeURIComponent(khomun.khapraki)" target="_blank">{{khomun.khapraki}}</a>
      </div>
      <div v-if="['walidetanime','walidetgame','walidetkhondang'].includes(khomun.chuetarang)">
        <a :href="'/walidet/'+khomun.khapraki" target="_blank">{{khomun.khapraki}}</a>
      </div>
      <div v-if="khomun.chuetarang=='kyara'">
        <a :href="'/tualakhon/'+khomun.khapraki" target="_blank">{{khomun.khomuntuani.th}} ~ {{khomun.khomuntuani.namae}}</a>
      </div>
      <div v-for="s in sathana" style="color: #161">
        {{s}}
      </div><br>
    </div>
    <v-container class="d-flex flex-wrap">
      <div v-for="k in Object.keys(khomun.khomuntuani)" style="width: 50%; border: 1px solid #123">
        <v-text-field filled v-model="khomun.khomuntuani[k]" :label="k" class="title" color="#193" style="margin: 5px"></v-text-field>
      </div>
    </v-container>
    <v-container class="d-flex">
      <v-btn class="title" color="blue darken-1" @click="banthuek()">บันทึก</v-btn>
      <v-btn class="title" color="warning" @click="lop()">ลบทิ้ง</v-btn>
    </v-container>
  </div>
</template>

<script>
import axios from 'axios';
import axioscsrf from '../buki/axioscsrf';
axioscsrf(axios);

export default {
  props: {
    khomun: Object,
  },
  data(){
    return {
      sathana: []
    }
  },
  computed: {

  },
  methods: {
    async banthuek(){ // บันทึกข้อมูล
      let url = "/api/kae/" + this.khomun.chuetarang+"/" + encodeURIComponent(this.khomun.khapraki);
      let r = await axios.patch(url,{khomun: this.khomun.khomuntuani}); // ทำการบันทึก
      console.log(r);
      this.sathana.push("บันทึกเรียบร้อย") // แสดงข้อความไว้ด้านบนว่าบันทึกได้สำเร็จ
    },
    plian(x) { // เปลี่ยนแก้ส่วนปีเดือนวัน
      x = x.replace(/[一-龥]/,"-"); // แก้พวกคันจิเป็นขีด "-"
      if(x.split("-").length<=3 && /\-$/.test(x)) {
        return x+"01"; // หากท้ายสุดมีขีด "-" ให้เพิ่มเลข 1 ต่อไปด้วย
      }
      if(x.slice(-1)=='-') return x.slice(0,-1);
      return x;
    },
    async lop() { // เมื่อกดปุ่มลบ
      if(confirm('จะลบแน่หรือ?')){
        let url = "/api/lop/" + this.khomun.chuetarang+"/" + encodeURIComponent(this.khomun.khapraki);
        let r = await axios.delete(url,{khomun: this.khomun.khomuntuani});
        console.log(r);
        this.sathana.push("ลบเรียบร้อย") // แสดงข้อความว่าลบแล้วไว้ด้านบน
      }
    } 
  },
  created(){
    delete this.khomun.khomuntuani.created_at // ไม่ต้องใช้ข้อมูลเวลาสร้างและแก้
    delete this.khomun.khomuntuani.updated_at
  },
  watch: { // เปลี่ยนแก้ส่วนปีเดือนวัน
    "khomun.khomuntuani.sngp"(mai,kao){
      this.khomun.khomuntuani.sngp = this.plian(mai);
    },
    "khomun.khomuntuani.ngp"(mai,kao){
      this.khomun.khomuntuani.ngp = this.plian(mai);
    }
  }
}
</script>