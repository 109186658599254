var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "title" }, [_vm._v("เพิ่มวลีเด็ด")]),
      _vm._v(" "),
      _c("textarea", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.khokhwam,
            expression: "khokhwam"
          }
        ],
        staticStyle: { width: "100%", border: "#00f solid 2px" },
        attrs: { name: "thangmot", rows: "8" },
        domProps: { value: _vm.khokhwam },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.khokhwam = $event.target.value
          }
        }
      }),
      _c("br"),
      _vm._v(" "),
      _c(
        "v-btn",
        {
          staticClass: "primary",
          on: {
            click: function($event) {
              return _vm.yaeksuan()
            }
          }
        },
        [_vm._v("ทำการแยกส่วนแล้วยัดใส่ด้านล่าง")]
      ),
      _vm._v(" "),
      _c(
        "v-btn",
        {
          staticClass: "warning",
          on: {
            click: function($event) {
              return _vm.lopmot()
            }
          }
        },
        [_vm._v("ลบทั้งหมด")]
      ),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _vm._l(_vm.sathana, function(s) {
        return _c(
          "div",
          { staticClass: "title", staticStyle: { color: "#1e1" } },
          [
            _c("a", { attrs: { href: s[1], target: "_blank" } }, [
              _vm._v(_vm._s(s[0]))
            ])
          ]
        )
      }),
      _vm._v(" "),
      _c(
        "table",
        { staticStyle: { width: "100%" }, attrs: { border: "1" } },
        [
          _c("tr", [
            _c("td"),
            _vm._v(" "),
            _c("td", { staticClass: "v-flex" }, [
              _c("span", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.muat,
                      expression: "muat"
                    }
                  ],
                  staticStyle: { background: "#fff", border: "#00f solid 2px" },
                  attrs: { type: "radio", value: "anime" },
                  domProps: { checked: _vm._q(_vm.muat, "anime") },
                  on: {
                    change: function($event) {
                      _vm.muat = "anime"
                    }
                  }
                }),
                _vm._v(" อนิเมะ")
              ]),
              _vm._v(" "),
              _c("span", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.muat,
                      expression: "muat"
                    }
                  ],
                  staticStyle: { background: "#fff", border: "#00f solid 2px" },
                  attrs: { type: "radio", value: "game" },
                  domProps: { checked: _vm._q(_vm.muat, "game") },
                  on: {
                    change: function($event) {
                      _vm.muat = "game"
                    }
                  }
                }),
                _vm._v(" เกม")
              ]),
              _vm._v(" "),
              _c("span", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.muat,
                      expression: "muat"
                    }
                  ],
                  staticStyle: { background: "#fff", border: "#00f solid 2px" },
                  attrs: { type: "radio", value: "khondang" },
                  domProps: { checked: _vm._q(_vm.muat, "khondang") },
                  on: {
                    change: function($event) {
                      _vm.muat = "khondang"
                    }
                  }
                }),
                _vm._v(" คนดัง")
              ])
            ])
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("td", [_vm._v("id")]),
            _c("td", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.walidet.lek,
                    expression: "walidet.lek"
                  }
                ],
                staticStyle: {
                  width: "220px",
                  background: "#fff",
                  border: "#00f solid 2px"
                },
                attrs: {
                  type: "text",
                  style:
                    "width: 220px; background: #fff;border: #00f solid 2px;"
                },
                domProps: { value: _vm.walidet.lek },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.walidet, "lek", $event.target.value)
                  }
                }
              }),
              _vm._v(" "),
              _c(
                "a",
                {
                  attrs: { href: "https://www.facebook.com/" + _vm.walidet.lek }
                },
                [_vm._v("facebook")]
              )
            ])
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("td", [_vm._v("วลีเด็ด")]),
            _c("td", [
              _c("textarea", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.walidet.wali_nh,
                    expression: "walidet.wali_nh"
                  }
                ],
                staticStyle: { width: "100%", border: "#00f solid 2px" },
                attrs: { name: "wali_nh", rows: "3" },
                domProps: { value: _vm.walidet.wali_nh },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.walidet, "wali_nh", $event.target.value)
                  }
                }
              })
            ])
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("td", [_vm._v("คำแปล")]),
            _c("td", [
              _c("textarea", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.walidet.wali_th,
                    expression: "walidet.wali_th"
                  }
                ],
                staticStyle: { width: "100%", border: "#00f solid 2px" },
                attrs: { name: "wali_th", rows: "3" },
                domProps: { value: _vm.walidet.wali_th },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.walidet, "wali_th", $event.target.value)
                  }
                }
              })
            ])
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("td", [_vm._v("romaji")]),
            _c("td", [
              _c("textarea", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.walidet.wali_ro,
                    expression: "walidet.wali_ro"
                  }
                ],
                staticStyle: { width: "100%", border: "#00f solid 2px" },
                attrs: { name: "wali_ro", rows: "3" },
                domProps: { value: _vm.walidet.wali_ro },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.walidet, "wali_ro", $event.target.value)
                  }
                }
              })
            ])
          ]),
          _vm._v(" "),
          _vm.muat == "anime" || _vm.muat == "game"
            ? [
                _c("tr", [
                  _c("td", [_vm._v("ชื่ออนิเมะ/เกม")]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v("\n          ญี่ปุ่น\n          "),
                    _vm.muat == "anime"
                      ? _c("span", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.walidet.anime_n,
                                expression: "walidet.anime_n"
                              }
                            ],
                            staticStyle: {
                              width: "90%",
                              background: "#fff",
                              border: "#00f solid 2px"
                            },
                            attrs: { type: "text" },
                            domProps: { value: _vm.walidet.anime_n },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.walidet,
                                  "anime_n",
                                  $event.target.value
                                )
                              }
                            }
                          }),
                          _c("br"),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              attrs: { href: "/anime/" + _vm.walidet.anime_n }
                            },
                            [_vm._v(_vm._s(_vm.walidet.anime_n))]
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.muat == "game"
                      ? _c("span", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.walidet.game_n,
                                expression: "walidet.game_n"
                              }
                            ],
                            staticStyle: {
                              width: "90%",
                              background: "#fff",
                              border: "#00f solid 2px"
                            },
                            attrs: { type: "text" },
                            domProps: { value: _vm.walidet.game_n },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.walidet,
                                  "game_n",
                                  $event.target.value
                                )
                              }
                            }
                          }),
                          _c("br"),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              attrs: {
                                href:
                                  "/zenigame/" +
                                  encodeURIComponent(_vm.walidet.game_n)
                              }
                            },
                            [_vm._v(_vm._s(_vm.walidet.game_n))]
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v("โรมาจิ "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.romaji,
                          expression: "romaji"
                        }
                      ],
                      staticStyle: {
                        width: "90%",
                        background: "#fff",
                        border: "#00f solid 2px"
                      },
                      attrs: { type: "text" },
                      domProps: { value: _vm.romaji },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.romaji = $event.target.value
                        }
                      }
                    })
                  ])
                ]),
                _vm._v(" "),
                _c(
                  "tr",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.muat == "anime",
                        expression: "muat=='anime'"
                      }
                    ]
                  },
                  [
                    _c("td", [_vm._v("ตอน")]),
                    _c("td", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.walidet.ton,
                            expression: "walidet.ton"
                          }
                        ],
                        staticStyle: {
                          width: "170px",
                          background: "#fff",
                          border: "#00f solid 2px"
                        },
                        attrs: { type: "input", outlined: "" },
                        domProps: { value: _vm.walidet.ton },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.walidet, "ton", $event.target.value)
                          }
                        }
                      })
                    ])
                  ]
                ),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [_vm._v("ตัวละคร")]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v("\n          หมายเลข "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.walidet.doi_id,
                          expression: "walidet.doi_id"
                        }
                      ],
                      staticStyle: {
                        width: "150px",
                        background: "#fff",
                        border: "#00f solid 2px"
                      },
                      attrs: { type: "text" },
                      domProps: { value: _vm.walidet.doi_id },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.walidet, "doi_id", $event.target.value)
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        attrs: {
                          href: "/tualakhon/" + _vm.walidet.doi_id,
                          target: "_blank"
                        }
                      },
                      [_vm._v(_vm._s(_vm.kyara.namae))]
                    ),
                    _c("br"),
                    _vm._v("\n          ชื่อญี่ปุ่น "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.kyara.namae,
                          expression: "kyara.namae"
                        }
                      ],
                      staticStyle: {
                        width: "200px",
                        background: "#fff",
                        border: "#00f solid 2px"
                      },
                      attrs: { type: "text" },
                      domProps: { value: _vm.kyara.namae },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.kyara, "namae", $event.target.value)
                        }
                      }
                    }),
                    _c("br"),
                    _vm._v("\n          ชื่อไทย "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.kyara.th,
                          expression: "kyara.th"
                        }
                      ],
                      staticStyle: {
                        width: "200px",
                        background: "#fff",
                        border: "#00f solid 2px"
                      },
                      attrs: { type: "text" },
                      domProps: { value: _vm.kyara.th },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.kyara, "th", $event.target.value)
                        }
                      }
                    }),
                    _c("br"),
                    _vm._v("\n          ชื่ออื่นๆ "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.kyara.hoka,
                          expression: "kyara.hoka"
                        }
                      ],
                      staticStyle: {
                        width: "200px",
                        background: "#fff",
                        border: "#00f solid 2px"
                      },
                      attrs: { type: "text" },
                      domProps: { value: _vm.kyara.hoka },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.kyara, "hoka", $event.target.value)
                        }
                      }
                    })
                  ])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [_vm._v("นักพากย์")]),
                  _vm._v(" "),
                  _c("td", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.walidet.seiyuu_n,
                          expression: "walidet.seiyuu_n"
                        }
                      ],
                      attrs: { type: "text" },
                      domProps: { value: _vm.walidet.seiyuu_n },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.walidet, "seiyuu_n", $event.target.value)
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        attrs: {
                          href: "/seiyuu/" + _vm.walidet.seiyuu_n,
                          target: "_blank"
                        }
                      },
                      [_vm._v(_vm._s(_vm.walidet.seiyuu_n))]
                    )
                  ])
                ])
              ]
            : [
                _c("tr", [
                  _c("td", [_vm._v("ผู้พูด")]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v("\n          ชื่อญี่ปุ่น "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.walidet.doi_n,
                          expression: "walidet.doi_n"
                        }
                      ],
                      staticStyle: {
                        width: "300px",
                        background: "#fff",
                        border: "#00f solid 2px"
                      },
                      attrs: { type: "text" },
                      domProps: { value: _vm.walidet.doi_n },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.walidet, "doi_n", $event.target.value)
                        }
                      }
                    }),
                    _c("br"),
                    _vm._v("\n          ชื่อไทย "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.khondang.th,
                          expression: "khondang.th"
                        }
                      ],
                      staticStyle: {
                        width: "300px",
                        background: "#fff",
                        border: "#00f solid 2px"
                      },
                      attrs: { type: "text" },
                      domProps: { value: _vm.khondang.th },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.khondang, "th", $event.target.value)
                        }
                      }
                    })
                  ])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [_vm._v("เป็น")]),
                  _c("td", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.khondang.dare,
                          expression: "khondang.dare"
                        }
                      ],
                      staticStyle: {
                        width: "300px",
                        background: "#fff",
                        border: "#00f solid 2px"
                      },
                      attrs: { type: "text" },
                      domProps: { value: _vm.khondang.dare },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.khondang, "dare", $event.target.value)
                        }
                      }
                    })
                  ])
                ])
              ],
          _vm._v(" "),
          _c("tr", [
            _c("td"),
            _c(
              "td",
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "primary",
                    on: {
                      click: function($event) {
                        return _vm.banthuek()
                      }
                    }
                  },
                  [_vm._v("บันทึกเพิ่มข้อมูล")]
                )
              ],
              1
            )
          ])
        ],
        2
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }