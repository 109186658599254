<template>
  <div>
    <div v-if="miphakto"><input type="checkbox" v-model="ruam_phakto"> รวมภาคต่อ</div>
    <v-card v-for="(w,i) in walidet_thimi" shaped elevation="10" color="#c2ebed" class="d-inline-flex flex-column" style="border: solid #25b069 4px; margin: 5px">
      <div align="right">~{{i+1}}~</div>
      <v-container align="left">
        <table>
          <tr><td class="font-weight-bold" style="width: 80px">ภาษาญี่ปุ่น<v-divider></v-divider></td><td>{{w.wali_nh}}<v-divider></v-divider></td></tr>
          <tr><td class="font-weight-bold">คำแปลไทย<v-divider></v-divider></td><td>{{w.wali_th}}<v-divider></v-divider></td></tr>
          <tr><td></td><td><a :href="'/walidet/'+w.lek" data-method="get">คลิกเพื่อดูรายละเอียดทั้งหมด</a></td></tr>
        </table>
      </v-container>
    </v-card>
  </div>
</template>

<script>
// กรอบแสดงวลีเด็ดของอนิเมะหรือเกม
export default {
  props: {
    miphakto: Boolean, // มีภาคต่อหรือไม่
    walidet: Array, // ข้อมูลวลีเด็ดภาคหลัก
    walidet_phakto: Array // ข้อมูลวลีเด็ดของภาคต่อ
  },
  data(){
    return {
      ruam_phakto: false // แสดงภาคต่อด้วยหรือไม่
    }
  },
  computed: {
    walidet_thimi(){ // วลีเด็ดที่จะแสดง หลังจากพิจารณาว่าเอาภาคต่อหรือไม่
      if(this.ruam_phakto) return this.walidet.concat(this.walidet_phakto);
      else return this.walidet;
    }
  }
}
</script>