var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-data-table", {
        attrs: {
          headers: _vm.cola,
          items: _vm.khomunnaitarang,
          "items-per-page": _vm.nala,
          page: _vm.nathi,
          "sort-by": _vm.khomun.praki,
          "hide-default-footer": "",
          dark: ""
        },
        on: {
          "update:itemsPerPage": function($event) {
            _vm.nala = $event
          },
          "update:items-per-page": function($event) {
            _vm.nala = $event
          }
        },
        scopedSlots: _vm._u([
          {
            key: "top",
            fn: function() {
              return [
                _c("v-card-title", [
                  _c("table", [
                    _c("tr", { attrs: { dense: "" } }, [
                      _c(
                        "td",
                        { staticClass: "subtitle-1" },
                        [
                          _vm._v("แสดงหน้าละ\n              "),
                          _c("v-text-field", {
                            staticStyle: { width: "120px" },
                            attrs: {
                              min: "5",
                              max: _vm.khomunnaitarang.length,
                              type: "number",
                              filled: "",
                              outlined: "",
                              dense: ""
                            },
                            model: {
                              value: _vm.nala,
                              callback: function($$v) {
                                _vm.nala = _vm._n($$v)
                              },
                              expression: "nala"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "td",
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "primary",
                              on: {
                                click: function($event) {
                                  return _vm.sadaengmot()
                                }
                              }
                            },
                            [_vm._v("แสดงทั้งหมด")]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "td",
                        { staticClass: "subtitle-1" },
                        [
                          _vm._v("หน้าที่\n              "),
                          _c("v-text-field", {
                            staticStyle: { width: "80px" },
                            attrs: {
                              min: "1",
                              max: parseInt(
                                _vm.khomunnaitarang.length / _vm.nala + 1
                              ),
                              type: "number",
                              filled: "",
                              outlined: "",
                              dense: ""
                            },
                            model: {
                              value: _vm.nathi,
                              callback: function($$v) {
                                _vm.nathi = _vm._n($$v)
                              },
                              expression: "nathi"
                            }
                          })
                        ],
                        1
                      )
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c(
                  "v-toolbar",
                  { attrs: { flat: "", color: "#337" } },
                  [
                    _c("v-toolbar-title", [
                      _vm._v("ตารางข้อมูล " + _vm._s(_vm.khomun.chuetarang))
                    ]),
                    _vm._v(" "),
                    _c("v-divider", { attrs: { inset: "", vertical: "" } }),
                    _vm._v(" "),
                    _c("v-spacer"),
                    _vm._v(" "),
                    _c(
                      "v-dialog",
                      {
                        attrs: { "max-width": "90%" },
                        scopedSlots: _vm._u([
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g({ attrs: { color: "primary" } }, on),
                                  [_vm._v("เพิ่มข้อมูลใหม่")]
                                )
                              ]
                            }
                          }
                        ]),
                        model: {
                          value: _vm.sadaengkropkae,
                          callback: function($$v) {
                            _vm.sadaengkropkae = $$v
                          },
                          expression: "sadaengkropkae"
                        }
                      },
                      [
                        _vm._v(" "),
                        _c(
                          "v-card",
                          [
                            _c("v-card-title", [
                              _c("span", { staticClass: "headline" }, [
                                _vm._v(_vm._s(_vm.khokhwamkrop))
                              ])
                            ]),
                            _vm._v(" "),
                            _c(
                              "v-card-text",
                              [
                                _c(
                                  "v-container",
                                  { staticClass: "d-flex flex-wrap" },
                                  _vm._l(_vm.cola, function(h) {
                                    return !["kaelop", "cu"].includes(h.value)
                                      ? _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              width: "50%",
                                              border: "1px solid #123"
                                            }
                                          },
                                          [
                                            _c("v-text-field", {
                                              staticStyle: { margin: "5px" },
                                              attrs: { label: h.value },
                                              model: {
                                                value: _vm.khomunmai[h.value],
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.khomunmai,
                                                    h.value,
                                                    $$v
                                                  )
                                                },
                                                expression: "khomunmai[h.value]"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  }),
                                  0
                                )
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "v-card-actions",
                              [
                                _c("v-spacer"),
                                _vm._v(" "),
                                _c(
                                  "v-btn",
                                  {
                                    attrs: { color: "blue darken-1", text: "" },
                                    on: { click: _vm.pitkrop }
                                  },
                                  [_vm._v("ยกเลิก")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-btn",
                                  {
                                    attrs: { color: "blue darken-1", text: "" },
                                    on: { click: _vm.banthuek }
                                  },
                                  [_vm._v("บันทึก")]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            },
            proxy: true
          },
          {
            key: "item.kaelop",
            fn: function(ref) {
              var item = ref.item
              return [
                _c(
                  "a",
                  {
                    attrs: {
                      href:
                        "/ami/kae/" +
                        _vm.khomun.chuetarang +
                        "/" +
                        encodeURIComponent(item[_vm.khomun.praki]),
                      target: "_blank"
                    }
                  },
                  [_vm._v("ไปหน้าแก้")]
                ),
                _vm._v(" "),
                _c(
                  "v-btn",
                  {
                    attrs: { color: "#3a3" },
                    on: {
                      click: function($event) {
                        return _vm.kae(item)
                      }
                    }
                  },
                  [_vm._v("แก้")]
                ),
                _vm._v(" "),
                _c(
                  "v-btn",
                  {
                    attrs: { color: "#a33" },
                    on: {
                      click: function($event) {
                        return _vm.lop(item)
                      }
                    }
                  },
                  [_vm._v("ลบ")]
                )
              ]
            }
          },
          {
            key: "item.cu",
            fn: function(ref) {
              var item = ref.item
              return [
                _c(
                  "div",
                  {
                    staticClass: "overline font-weight-bold",
                    staticStyle: { color: "#cce" }
                  },
                  [
                    _vm._v(
                      _vm._s(item.created_at.split(".")[0].replace("T", " "))
                    )
                  ]
                ),
                _vm._v(" "),
                _c("hr"),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "overline font-weight-bold",
                    staticStyle: { color: "#ecf" }
                  },
                  [
                    _vm._v(
                      _vm._s(item.updated_at.split(".")[0].replace("T", " "))
                    )
                  ]
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }