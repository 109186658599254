<template>
  <v-container>
    <div class="headline font-weight-bold">
      {{khomun.anime.namae}}
      <span v-if="khomun.anime.namae!=khomun.anime.romaji"><br>{{khomun.anime.romaji}}</span>
    </div>
    <div class="title font-weight-bold" align="left">ข้อมูลอนิเมะ</div>
    <v-card shaped style="border: solid #999 3px">
      <v-container>
        <table class="title">
          <tr><td>ชื่ออนิเมะ :</td><td>{{khomun.anime.namae}}</td></tr>
          <tr><td>คานะ :</td><td>{{khomun.anime.kana}}</td></tr>
          <tr v-if="khomun.anime.namae!=khomun.anime.romaji"><td>ชื่อโรมาจิ :</td>{{khomun.anime.romaji}}<td></td></tr>
          <tr><td>วันที่ฉาย :</td>{{dueanpi(khomun.anime.ngp)}}<td></td></tr>
          <tr><td></td><td><a :href="urlwiki" target="_blank">wikipedia</a></td></tr>
          <tr><td>มีวลีเด็ดทั้งหมด :</td><td>{{khomun.walidet.length}}<span v-if="khomun.miphakto"> (รวมภาคต่อเป็น {{khomun.walidet.length+khomun.walidet_phakto.length}})</span></td></tr>
          <tr v-if="khomun.miphakto">
            <td>ภาคต่อ :</td>
            <td>
              <div v-for="a in khomun.arr_phakto">
                <a :href="'/anime/'+encodeURIComponent(a.namae)" data-method="get">{{a.namae}} <span v-if="a.namae!=a.romaji">/ {{a.romaji}}</span></a>
              </div>
            </td>
          </tr>
          <tr v-if="khomun.pkaklak"><td>เป็นภาคต่อของ: </td><td><a :href="'/anime/'+encodeURIComponent(khomun.pkaklak.namae)" data-method="get">{{khomun.pkaklak.namae}} <span v-if="khomun.pkaklak.namae!=khomun.pkaklak.romaji">/ {{khomun.pkaklak.romaji}}</span></a></td></tr>
        </table>
      </v-container>
    </v-card>
    <riangphapwalidet :walidet="khomun.walidet"></riangphapwalidet>
    <br>
    <div class="title font-weight-bold" align="left">ตัวละครและนักพากย์</div>
    <kropkyaraseiyuu :khomun="khomun"></kropkyaraseiyuu>
    <br>
    <div class="title font-weight-bold" align="left">วลีเด็ดทั้งหมดจากอนิเมะเรื่องนี้</div>
    <kropruamwalidet :walidet="khomun.walidet" :walidet_phakto="khomun.walidet_phakto" :miphakto="khomun.miphakto"></kropruamwalidet>
    <v-container class="fb-like" data-share="true" data-width="450" data-show-faces="true"></v-container>
  </v-container>
</template>

<script>
import kropkyaraseiyuu from './kropkyaraseiyuu.vue';
import kropruamwalidet from './kropruamwalidet.vue';
import riangphapwalidet from './riangphapwalidet.vue';
import dueanpi from '../buki/dueanpi.js';

export default {
  components: {
    kropkyaraseiyuu, // กรอบแสดงตัวละครและนักพากย์
    kropruamwalidet, // กรอบแสดงวลีเด็ดทั้งหมดในนี้
    riangphapwalidet // ภาพเรียงวลีเด็ดสุ่ม
  },
  props: {
    khomun: Object,
  },
  data() {
    return {
      dueanpi // ฟังก์ชันแปลงวันเดือนปีภาษาไทย
    }
  },
  computed: {
    urlwiki() { // url ของหน้าวิกิพีเดียของอนิเมะเรื่องนี้ ตัดเลขภาคออกด้วย
      return "https://ja.wikipedia.org/wiki/" + encodeURIComponent(this.khomun.anime.namae).replace(/ \d$/,"")
    }
  }
}
</script>