var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-container", [
    _c("div", { staticClass: "display-1 font-weight-bold" }, [
      _c("u", [_vm._v("เกี่ยวกับเว็บนี้")])
    ]),
    _vm._v(" "),
    _c("br"),
    _vm._v(" "),
    _c("div", { attrs: { align: "left" } }, [
      _vm._v("\n  เว็บนี้เป็นที่เก็บรวบรวมวลีเด็ดของแฟนเพจ "),
      _c(
        "a",
        {
          attrs: {
            href: "https://www.facebook.com/Animankyafu",
            target: "_blank"
          }
        },
        [_vm._v("https://www.facebook.com/Animankyafu")]
      ),
      _vm._v(" เพื่อเก็บเรียบเรียงข้อมูลให้เป็นระเบียบและสามารถค้นได้ง่าย"),
      _c("br"),
      _vm._v(" "),
      _c("br"),
      _vm._v(
        "\n  หวังเป็นอย่างยิ่งว่าเว็บนี้จะเป็นประโยชน์สำหรับผู้ที่สนใจญี่ปุ่นทุกคน"
      ),
      _c("br"),
      _vm._v(" "),
      _c("br"),
      _vm._v("\n  ข้อมูลแบ่งโดยหลัก ๆ เป็น 3 ส่วนคือ"),
      _c(
        "a",
        { attrs: { href: "/walidetthangmot/anime", "data-method": "get" } },
        [_vm._v("วลีเด็ดจากอนิเมะ")]
      ),
      _vm._v(", "),
      _c(
        "a",
        { attrs: { href: "/walidetthangmot/anime", "data-method": "get" } },
        [_vm._v("วลีเด็ดจากเกม")]
      ),
      _vm._v(" และ "),
      _c(
        "a",
        { attrs: { href: "/walidetthangmot/anime", "data-method": "get" } },
        [_vm._v("วลีเด็ดจากคนดังชาวญี่ปุ่น")]
      ),
      _vm._v(" ทั้งหมดนี้ลงเอาไว้ในแฟนเพจด้วย แล้วก็เอามาลงในนี้อีกที"),
      _c("br"),
      _vm._v(" "),
      _c("br"),
      _vm._v(
        "\n  ส่วนวลีเด็ดจำพวกอื่นเช่นจากมังงะหรือตามเว็บต่างๆยังมีไม่มากจึงไม่ได้รวบรวมลงเว็บ"
      ),
      _c("br"),
      _vm._v(" "),
      _c("br"),
      _vm._v(
        "\n  ในเว็บนี้มีข้อมูลบางส่วนที่ใส่เพิ่มเติมมากขึ้นกว่าจากในแฟนเพจ เช่น ข้อมูลนักพากย์ สามารถค้นวลีเด็ดอนิเมะและเกมจากชื่อนักพากย์ที่พากย์ตัวละครที่พูดวลีเด็ดได้ >> "
      ),
      _c("a", { attrs: { href: "/seiyuuthangmot", "data-method": "get" } }, [
        _vm._v("วลีเด็ดจากอนิเมะและเกมแบ่งตามนักพากย์")
      ]),
      _c("br"),
      _vm._v(" "),
      _c("br"),
      _vm._v(
        "\n  แฟนเพจถูกสร้างขึ้นในปี 2013 ดังนั้นวลีเด็ดส่วนใหญ่จึงมาจากปีนั้นเป็นต้นมา ปีเก่าๆมีอยู่น้อย"
      ),
      _c("br"),
      _vm._v(" "),
      _c("br"),
      _vm._v(
        "\n  หากมีข้อสงสัยหรือพบข้อมูลผิดพลาดโปรดติดต่อผู้ดูแลแฟนเพจผ่านทาง facebook"
      ),
      _c("br"),
      _vm._v(" "),
      _c("br"),
      _vm._v(
        "\n  และได้รับความช่วยเหลือจาก //@etc (หนึ่งในผู้ดูแลแฟนเพจ ผู้ก่อตั้งแฟนเพจ)"
      ),
      _c("br"),
      _vm._v(" "),
      _c("br")
    ]),
    _vm._v(" "),
    _c("br"),
    _vm._v(" "),
    _c("div", { staticClass: "title", attrs: { align: "center" } }, [
      _vm._v("บันทึกเหตุการณ์ที่สำคัญเกี่ยวกับเว็บนี้")
    ]),
    _vm._v(" "),
    _c("table", { staticStyle: { "vertical-align": "top" } }, [
      _c("tr", [
        _c("td", [_vm._v("-- 18/03/2013 --"), _c("br")]),
        _vm._v(" "),
        _c("td", { staticStyle: { padding: "20px" } }, [
          _vm._v("\n        เปิดตัวแฟนเพจรวมคำแปลวลีเด็ด"),
          _c("br"),
          _vm._v("\n        >> "),
          _c(
            "a",
            {
              attrs: {
                href: "https://www.facebook.com/Animankyafu",
                target: "_blank"
              }
            },
            [_vm._v("https://www.facebook.com/Animankyafu")]
          )
        ])
      ]),
      _vm._v(" "),
      _c("tr", [
        _c("td", [_vm._v("-- 17/11/2015 --"), _c("br")]),
        _vm._v(" "),
        _c("td", { staticStyle: { padding: "20px" } }, [
          _vm._v("\n        เปิดตัวเว็บครั้งแรกที่ https://animankyafu.hol.es"),
          _c("br"),
          _vm._v("\n        >> "),
          _c(
            "a",
            {
              attrs: {
                href: "https://phyblas.hinaboshi.com/20151117",
                target: "_blank"
              }
            },
            [_vm._v("https://phyblas.hinaboshi.com/20151117")]
          )
        ])
      ]),
      _vm._v(" "),
      _c("tr", [
        _c("td", [_vm._v("-- 13/08/2016 --")]),
        _vm._v(" "),
        _c("td", { staticStyle: { padding: "20px" } }, [
          _vm._v(
            "\n        เว็บ https://animankyafu.hol.es ได้ถูกปิดลง\n      "
          )
        ])
      ]),
      _vm._v(" "),
      _c("tr", [
        _c("td", [_vm._v("-- 20/08/2016 --"), _c("br")]),
        _vm._v(" "),
        _c("td", { staticStyle: { padding: "20px" } }, [
          _vm._v("\n        ย้ายเว็บมายัง http://hinaboshi.com"),
          _c("br"),
          _vm._v("\n        >> "),
          _c(
            "a",
            {
              attrs: {
                href: "https://phyblas.hinaboshi.com/20160820",
                target: "_blank"
              }
            },
            [_vm._v("https://phyblas.hinaboshi.com/20160820")]
          )
        ])
      ]),
      _vm._v(" "),
      _c("tr", [
        _c("td", [_vm._v("-- 24/06/2017 --")]),
        _vm._v(" "),
        _c("td", { staticStyle: { padding: "20px" } }, [
          _vm._v(
            "\n        เพิ่มระบบ meta-tag ลงไปให้มีเนื้อหาแสดงเวลาแชร์ลง facebook"
          ),
          _c("br")
        ])
      ]),
      _vm._v(" "),
      _c("tr", [
        _c("td", [_vm._v("-- 10/04/2020 --")]),
        _vm._v(" "),
        _c("td", { staticStyle: { padding: "20px" } }, [
          _vm._v(
            "\n        ปรับปรุงเว็บใหม่ให้กลายเป็น responsive และ material design โดยใช้ vuetify"
          ),
          _c("br"),
          _vm._v("\n        >> "),
          _c(
            "a",
            {
              attrs: {
                href: "https://phyblas.hinaboshi.com/20200411",
                target: "_blank"
              }
            },
            [_vm._v("https://phyblas.hinaboshi.com/20200411")]
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }