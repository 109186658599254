<template>
  <v-container>          
    <v-data-iterator
      :items="arr_seiyuu"
      :items-per-page.sync="nala"
      :page="nathi"
      hide-default-footer
    >
      <template v-slot:default="p">
        <div class="title">
          <span class="font-weight-bold">รายชื่อนักพากย์ที่มีข้อมูลวลีเด็ด</span> (แสดงชื่อที่ {{i_roem}} ถึง {{i_chop}} จากทั้งหมด {{arr_seiyuu.length}})
        </div>
        <v-card-title class="title d-flex justify-space-around">
          <span class="d-flex">
            <span class="subtitle-1">แสดงหน้าละ
              <v-text-field v-model.number="nala" min="5" :max="arr_seiyuu.length" type="number" style="width: 120px" filled outlined dense></v-text-field>
            </span>
            <span class="subtitle-1">หน้าที่
              <v-text-field v-model.number="nathi" min="1" :max="parseInt(arr_seiyuu.length/nala+1)" type="number" style="width: 80px" filled outlined dense></v-text-field>
            </span>
          </span>
          <span class="title flex-column d-flex">
            ค้นคำ
            <span class="d-flex subtitle-1">
              <span>ญี่ปุ่น <v-text-field v-model="khamkhon_nh" style="width: 160px" filled outlined dense></v-text-field></span>
              <span>ไทย <v-text-field v-model="khamkhon_th" style="width: 160px" filled outlined dense></v-text-field></span>
            </span>
          </span>
        </v-card-title>
        <v-row>
          <v-col v-for="c in arr_withiriang">
            <input type="radio" :key="c[0]" :value="c[0]" v-model="withiriang" > {{c[1]}}
          </v-col>
        </v-row>
        <v-row>
          <v-col></v-checkbox><input type="checkbox" v-model="riangklap">
          {{{1: 'เรียงจากหลัง',2: 'เรียงจากหลัง',3: 'เรียงจากอายุมาก',4: 'เรียงจากน้อย'}[withiriang]}}
          </v-col>
          <v-col>
            <input type="checkbox" v-model="aochaphothimiwankoet" :disabled="withiriang==3"> เอาเฉพาะที่มีวันเกิด
          </v-col>
          <v-col>
            <input type="checkbox" v-model="maiaochuerong"> เอาแค่ชื่อหลัก
          </v-col>
        </v-row>
        <div v-for="s in p.items" align="left" style="margin: 5px">
          <div v-if="s">
            <div class="d-flex justify-start title">
              <span v-if="s.kanren">~*&nbsp;</span>
              <span v-if="withiriang==3">[{{dueanpi(s.sngp,true,true)}}]&nbsp;</span>
              <a :href="'/seiyuu/'+s.namae" data-method="get">{{s.th}} <span v-if="s.namae!=s.th">~ {{s.namae}}</span></a>
              <span class="subtitle-1" style="color: #752">&nbsp;({{s.n_walidet}} = อนิเมะ {{s.n_walidetanime}} + เกม {{s.n_walidetgame}})</span>
            </div>
          </div>
          <div v-else class="title">
            -- ไม่พบคำที่ค้นหา --
          </div>
        </div>
        
      </template>
    </v-data-iterator>
    <v-container class="fb-like" data-share="true" data-width="450" data-show-faces="true"></v-container>
  </v-container>
</template>

<script>
// หน้าแสดงรายชื่อนักพากย์ทั้งหมด
import dueanpi from '../buki/dueanpi.js';
import kanahenkan from '../buki/kanahenkan.js';
import eaeoai from '../buki/eaeoai.js';

export default {
  props: {
    khomun: Object,
  },
  data() {
    return {
      dueanpi,
      khamkhon_nh: "", // คำค้นชื่อ ภาษาญี่ปุ่น
      khamkhon_th: "", // คำค้นชื่อ ภาาาไทย
      nala: 50, // แสดงหน้าละ
      nathi: 1, // หน้าที่แสดงอยู่
      arr_withiriang: [ // วิธีการเรียงทั้งหมด
        [1,'เรียงตามชื่อภาษาญี่ปุ่น'],
        [2,'เรียงตามชื่อภาษาไทย'],
        [3,'เรียงตามวันเกิด'],
        [4,'เรียงตามจำนวนวลีเด็ด']
      ],
      withiriang: this.khomun.riang, // วิธีการเรียงที่ใช้อยู่
      riangklap: false, // ติ๊กว่าเรียงกลับด้านหรือไม่
      maiaochuerong: false, // ติ๊กว่าไม่เอาพวกชื่อรองหรือไม่
      aochaphothimiwankoet: false // ติ๊กว่าจะให้แสดงเฉพาะที่มีข้อมูลวันเกิดหรือไม่
    }
  },
  computed: {
    arr_seiyuu() {
      let arr = this.khomun.arr_seiyuu;
      if(this.khamkhon_nh){ // คัดกรองตามคำค้นภาษาญี่ปุ่น
        arr = arr.filter((s)=>{
          return s.namae.includes(this.khamkhon_nh)||s.kana.includes(this.khamkhon_nh);
        })
      }
      if(this.khamkhon_th){ // คัดกรองตามคำค้นภาษาไทย
        arr = arr.filter((s)=>{
          return s.th.includes(this.khamkhon_th);
        })
      }
      if(this.withiriang==3||this.aochaphothimiwankoet){ // คัดเอาเฉพาะที่มีวันเกิด
        arr = arr.filter((s)=>{
          return s.sngp;
        });
      }
      else if(this.maiaochuerong){ // คัดเอาพวกชื่อรองออก
        arr = arr.filter((s)=>{
          return !(s.kanren);
        });
      }
      if(arr.length==0) arr = [null]; // ถ้าคัดแล้วไม่มีเลยให้ใส่ null ไป แทนที่จะปล่อยให้เป็นอาเรย์เปล่า
      this.riangmai(arr); // ทำการเรียงลำดับใหม่ตามวิธีที่กำหนด
      return arr;
    },
    i_roem(){ // ดัชนีตัวที่เริ่มต้น
      return Math.max(Math.min(1+(this.nathi-1)*this.nala, this.arr_seiyuu.length), 1);
    },
    i_chop(){ // ดัชนีตัวสุดท้าย
      return Math.max(Math.min(this.nathi*this.nala, this.arr_seiyuu.length), 1);
    }
  },
  methods: {
    riangmai(arr){ // ฟังก์ชันสำหรับทำการเรียงใหม่
      let riang
      if(this.withiriang==1){ // เรียงตามชื่อญี่ปุ่น
        riang = (a,b)=>{return {false: -1, true: 1}[kanahenkan(a.kana) > kanahenkan(b.kana)];};
      }
      else if(this.withiriang==2){ // เรียงตามชื่อภาษาไทย
        riang = (a,b)=>{return {false: -1, true: 1}[eaeoai(a.th) > eaeoai(b.th)];};
      }
      else if(this.withiriang==3){ // เรียงตามวันเดือนปีเกิด
        this.aochaphothimiwankoet = true; // กรณีจะเอาเฉพาะที่มีข้อมูลวันเกิด
        riang = (a,b)=>{return {false: -1, true: 1}[a.sngp < b.sngp];};
      }
      else{ // เรียงตามจำนวนวลีเด็ดที่มี
        riang = (a,b)=>{return {false: -1, true: 1}[a.n_walidet < b.n_walidet];};
      }
      arr.sort(riang); // เริ่มเรียงได้
      if(this.riangklap) arr.reverse() // กลับด้าน
      // แก้ url ตามวิธีการเรียง
      let lek = parseInt(this.withiriang);
      if(this.riangklap) lek += 4
      window.history.pushState(null, null, "/seiyuuthangmot/"+lek);
    }
  },
  created(){
    if(this.withiriang>4) {
      this.withiriang -= 4;
      this.riangklap = true;
    }
  }
}
</script>