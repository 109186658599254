var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c("v-data-iterator", {
        attrs: {
          items: _vm.arr_walidet,
          "items-per-page": _vm.nala,
          page: _vm.nathi,
          "hide-default-footer": ""
        },
        on: {
          "update:itemsPerPage": function($event) {
            _vm.nala = $event
          },
          "update:items-per-page": function($event) {
            _vm.nala = $event
          }
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(p) {
              return [
                _c(
                  "v-card-title",
                  { staticClass: "title d-flex justify-space-around" },
                  [
                    _c("span", { staticClass: "font-weight-bold" }, [
                      _vm._v("วลีเด็ด" + _vm._s(_vm.muat))
                    ]),
                    _vm._v(" "),
                    _vm.arr_walidet.length > 1
                      ? _c("span", [
                          _vm._v(
                            "(แสดงข้อความที่ " +
                              _vm._s(_vm.i_roem) +
                              " ถึง " +
                              _vm._s(_vm.i_chop) +
                              " จากทั้งหมด " +
                              _vm._s(_vm.arr_walidet.length) +
                              ")"
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("span", { staticClass: "d-flex" }, [
                      _c(
                        "span",
                        { staticClass: "subtitle-1" },
                        [
                          _vm._v("แสดงหน้าละ\n            "),
                          _c("v-text-field", {
                            staticStyle: { width: "80px" },
                            attrs: {
                              min: "5",
                              type: "number",
                              filled: "",
                              outlined: "",
                              dense: ""
                            },
                            model: {
                              value: _vm.nala,
                              callback: function($$v) {
                                _vm.nala = _vm._n($$v)
                              },
                              expression: "nala"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        { staticClass: "subtitle-1" },
                        [
                          _vm._v("หน้าที่\n            "),
                          _c("v-text-field", {
                            staticStyle: { width: "80px" },
                            attrs: {
                              min: "1",
                              max: parseInt(
                                _vm.arr_walidet.length / _vm.nala + 1
                              ),
                              type: "number",
                              filled: "",
                              outlined: "",
                              dense: ""
                            },
                            model: {
                              value: _vm.nathi,
                              callback: function($$v) {
                                _vm.nathi = _vm._n($$v)
                              },
                              expression: "nathi"
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "title flex-column d-flex" }, [
                      _vm._v("\n          ค้นคำ\n          "),
                      _c("span", { staticClass: "d-flex subtitle-1" }, [
                        _c(
                          "span",
                          [
                            _vm._v("ญี่ปุ่น "),
                            _c("v-text-field", {
                              staticStyle: { width: "160px" },
                              attrs: { filled: "", outlined: "", dense: "" },
                              model: {
                                value: _vm.khamkhon_nh,
                                callback: function($$v) {
                                  _vm.khamkhon_nh = $$v
                                },
                                expression: "khamkhon_nh"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "span",
                          [
                            _vm._v("ไทย "),
                            _c("v-text-field", {
                              staticStyle: { width: "160px" },
                              attrs: { filled: "", outlined: "", dense: "" },
                              model: {
                                value: _vm.khamkhon_th,
                                callback: function($$v) {
                                  _vm.khamkhon_th = $$v
                                },
                                expression: "khamkhon_th"
                              }
                            })
                          ],
                          1
                        )
                      ])
                    ])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticStyle: { margin: "3px" }, attrs: { align: "left" } },
                  _vm._l(p.items, function(w, i) {
                    return _c("v-container", [
                      w
                        ? _c(
                            "div",
                            [
                              _c("div", [
                                _vm._v("--" + _vm._s(i + _vm.i_roem) + "--")
                              ]),
                              _vm._v(" "),
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href: "/walidet/" + w.lek,
                                    "data-method": "get"
                                  }
                                },
                                [
                                  _vm._v(_vm._s(w.wali_nh)),
                                  _c("br"),
                                  _vm._v(_vm._s(w.wali_th))
                                ]
                              ),
                              _vm._v(" "),
                              _c("v-divider")
                            ],
                            1
                          )
                        : _c("div", { staticClass: "title" }, [
                            _vm._v(
                              "\n            -- ไม่พบคำที่ค้นหา --\n          "
                            )
                          ])
                    ])
                  }),
                  1
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }