<template>
  <v-container fluid>          
    <v-data-iterator
      :items="arr_walidet"
      :items-per-page.sync="nala"
      :page="nathi"
      hide-default-footer
    >
      <template v-slot:default="p">
        <v-card-title class="title d-flex justify-space-around">
          <span class="font-weight-bold">วลีเด็ด{{muat}}</span>
          <span v-if="arr_walidet.length>1">(แสดงข้อความที่ {{i_roem}} ถึง {{i_chop}} จากทั้งหมด {{arr_walidet.length}})</span>
          <span class="d-flex">
            <span class="subtitle-1">แสดงหน้าละ
              <v-text-field v-model.number="nala" min="5" type="number" style="width: 80px" filled outlined dense></v-text-field>
            </span>
            <span class="subtitle-1">หน้าที่
              <v-text-field v-model.number="nathi" min="1" :max="parseInt(arr_walidet.length/nala+1)" type="number" style="width: 80px" filled outlined dense></v-text-field>
            </span>
          </span>
          <span class="title flex-column d-flex">
            ค้นคำ
            <span class="d-flex subtitle-1">
              <span>ญี่ปุ่น <v-text-field v-model="khamkhon_nh" style="width: 160px" filled outlined dense></v-text-field></span>
              <span>ไทย <v-text-field v-model="khamkhon_th" style="width: 160px" filled outlined dense></v-text-field></span>
            </span>
          </span>
        </v-card-title>
        <div align="left" style="margin: 3px">
          <v-container v-for="(w,i) in p.items">
            <div v-if="w">
              <div>--{{i+i_roem}}--</div>
              <a :href="'/walidet/'+w.lek" data-method="get">{{w.wali_nh}}<br>{{w.wali_th}}</a>
              <v-divider></v-divider>
            </div>
            <div v-else class="title">
              -- ไม่พบคำที่ค้นหา --
            </div>
          </v-container>
        </div>
      </template>
    </v-data-iterator>
  </v-container>
</template>

<script>
import Vue from 'vue';
export default {
  props: {
    khomun: Object,
  },
  data(){
    return {
      khamkhon_nh: this.khomun.khamkhon_nh, // คำค้นวลีเด็ด ภาษาญี่ปุ่น
      khamkhon_th: this.khomun.khamkhon_th, // คำค้นวลีเด็ด ภาษาไทย
      nala: 30, // จำนวนหน้าที่แสดง
      nathi: 1 // เลขหน้าที่แสดง
    }
  },
  computed: {
    arr_walidet(){ // วลีเด็ดทั้งหมดหลังคัดกรองแล้ว
      let arr = this.khomun.walidet;
      if(this.khamkhon_nh){ // กรองตามคำค้นภาษาญี่ปุ่น
        arr = arr.filter((w)=>{
          return w.wali_nh.includes(this.khamkhon_nh);
        })
      }
      if(this.khamkhon_th){ // กรองตามคำค้นภาษาไทย
        arr = arr.filter((w)=>{
          return w.wali_th.includes(this.khamkhon_th);
        })
      }
      if(arr.length==0) arr = [null];
      return arr;
    },
    muat(){ // หมวด [อนิเมะ, เกม, คนดัง, ทั้งหมด]
      return {anime: "จากอนิเมะ", game: "จากเกม", khondang: "จากคนดัง", "": "ทั้งหมด"}[this.khomun.mode]
    },
    i_roem(){ // ดัชนีลำดับแรกที่แสดง
      return Math.max(Math.min(1+(this.nathi-1)*this.nala, this.arr_walidet.length), 1);
    },
    i_chop(){ // ดัชนีลำดับสุดท้่ายที่แสดง
      return Math.max(Math.min(this.nathi*this.nala, this.arr_walidet.length), 1);
    }
  },
  methods: {
    kae_url(){ // แก้ url
      window.history.pushState(null, null, "/walidetthangmot/"+this.khomun.mode+"๛"+this.khamkhon_nh+"๚"+this.khamkhon_th);
    }
  },
  watch: { // เมื่อมีการแก้ไขคำค้นก็เปลี่ยน url ไปด้วย
    khamkhon_nh(mai,kao){
      if(mai!=kao) this.kae_url()
    },
    khamkhon_th(mai,kao){
      if(mai!=kao) this.kae_url()
    }
  }
}
</script>

<style scoped>
  a:hover{
    color: #b23946;
  }
</style>