var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c("div", { staticClass: "headline font-weight-bold" }, [
        _vm._v("\n    " + _vm._s(_vm.khomun.seiyuu.th)),
        _c("br"),
        _vm._v(_vm._s(_vm.khomun.seiyuu.namae) + "\n  ")
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "title font-weight-bold", attrs: { align: "left" } },
        [_vm._v("ข้อมูลนักพากย์")]
      ),
      _vm._v(" "),
      _c(
        "v-card",
        { staticStyle: { border: "solid #999 3px" }, attrs: { shaped: "" } },
        [
          _c("v-container", [
            _c("table", { staticClass: "title" }, [
              _c("tr", [
                _c("td", [_vm._v("ชื่อญี่ปุ่น :")]),
                _c("td", [_vm._v(_vm._s(_vm.khomun.seiyuu.namae))])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("คานะ :")]),
                _c("td", [_vm._v(_vm._s(_vm.khomun.seiyuu.kana))])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("ชื่อไทย :")]),
                _c("td", [_vm._v(_vm._s(_vm.khomun.seiyuu.th))])
              ]),
              _vm._v(" "),
              _vm.khomun.seiyuu.sngp
                ? _c("tr", [
                    _c("td", [_vm._v("วันเกิด :")]),
                    _vm._v(
                      _vm._s(_vm.dueanpi(_vm.khomun.seiyuu.sngp, false, true))
                    ),
                    _c("td")
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.khomun.seiyuu.chueuen
                ? _c("tr", [
                    _c("td", [_vm._v("ชื่ออื่น :")]),
                    _vm._v(" "),
                    _c(
                      "td",
                      { staticClass: "d-flex" },
                      _vm._l(_vm.khomun.seiyuu.chueuen, function(c) {
                        return _c(
                          "v-card",
                          { staticStyle: { border: "1px solid #333" } },
                          [
                            _c("table", { staticClass: "subtitle-1" }, [
                              _c("tr", [
                                _c("td", [_vm._v("ชื่อญี่ปุ่น :")]),
                                _c("td", [_vm._v(_vm._s(c.namae))])
                              ]),
                              _vm._v(" "),
                              _c("tr", [
                                _c("td", [_vm._v("คานะ :")]),
                                _c("td", [_vm._v(_vm._s(c.kana))])
                              ]),
                              _vm._v(" "),
                              _c("tr", [
                                _c("td", [_vm._v("ชื่อไทย :")]),
                                _c("td", [_vm._v(_vm._s(c.th))])
                              ]),
                              _vm._v(" "),
                              _c("tr", [
                                _c("td", [_vm._v("มีวลีเด็ด: ")]),
                                _c("td", { staticStyle: { color: "#722" } }, [
                                  _vm._v(_vm._s(c.arr_walidetgame.length))
                                ])
                              ])
                            ])
                          ]
                        )
                      }),
                      1
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("tr", [
                _c("td"),
                _c("td", [
                  _c(
                    "a",
                    {
                      attrs: {
                        href:
                          "https://ja.wikipedia.org/wiki/" +
                          _vm.khomun.seiyuu.namae.replace(" ", ""),
                        target: "_blank"
                      }
                    },
                    [_vm._v("wikipedia")]
                  )
                ])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("มีวลีเด็ดทั้งหมด :")]),
                _c("td", { staticStyle: { color: "#722" } }, [
                  _vm._v(
                    _vm._s(
                      _vm.khomun.seiyuu.arr_walidetanime.length +
                        _vm.khomun.seiyuu.arr_walidetgame.length
                    ) +
                      " (อนิเมะ " +
                      _vm._s(_vm.khomun.seiyuu.arr_walidetanime.length) +
                      ", เกม " +
                      _vm._s(_vm.khomun.seiyuu.arr_walidetgame.length) +
                      ")"
                  )
                ])
              ])
            ])
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c("riangphapwalidet", { attrs: { walidet: _vm.arr_ruamwalidet } }),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _vm.khomun.seiyuu.arr_kyaraanime.length > 0
        ? _c(
            "div",
            [
              _c("div", { staticClass: "title font-weight-bold" }, [
                _vm._v("ผลงานพากย์อนิเมะ")
              ]),
              _vm._v(" "),
              _c(
                "v-card",
                {
                  staticStyle: { border: "solid #999 3px" },
                  attrs: { shaped: "", align: "left" }
                },
                [
                  _c(
                    "v-container",
                    _vm._l(_vm.khomun.seiyuu.arr_kyaraanime, function(ref) {
                      var k = ref[0]
                      var n = ref[1]
                      var aa = ref[2]
                      return _c(
                        "div",
                        { attrs: { align: "left" } },
                        [
                          _c(
                            "v-container",
                            [
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href: "/tualakhon/" + k.id,
                                    "data-method": "get"
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(k.th) + " ~ " + _vm._s(k.namae)
                                  ),
                                  k.hoka
                                    ? _c("span", [
                                        _vm._v(" ~ " + _vm._s(k.hoka))
                                      ])
                                    : _vm._e()
                                ]
                              ),
                              _vm._v(" (" + _vm._s(n) + ")"),
                              _c("br"),
                              _vm._v(" "),
                              _vm._l(aa, function(a, i) {
                                return _c("span", [
                                  i == 0
                                    ? _c("span", [_vm._v("จาก ")])
                                    : _c("span", [_vm._v(", ")]),
                                  _vm._v(" "),
                                  _c(
                                    "a",
                                    {
                                      attrs: {
                                        href:
                                          "/anime/" +
                                          encodeURIComponent(a.namae),
                                        "data-method": "get"
                                      }
                                    },
                                    [
                                      _vm._v(_vm._s(a.namae)),
                                      a.namae != a.romaji
                                        ? _c("span", [
                                            _vm._v(" / " + _vm._s(a.romaji))
                                          ])
                                        : _vm._e()
                                    ]
                                  )
                                ])
                              })
                            ],
                            2
                          )
                        ],
                        1
                      )
                    }),
                    0
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _vm.khomun.seiyuu.arr_kyaragame.length > 0
        ? _c(
            "div",
            [
              _c("div", { staticClass: "title font-weight-bold" }, [
                _vm._v("ผลงานพากย์เกม")
              ]),
              _vm._v(" "),
              _c(
                "v-card",
                {
                  staticStyle: { border: "solid #999 3px" },
                  attrs: { shaped: "", align: "left" }
                },
                [
                  _c(
                    "v-container",
                    _vm._l(_vm.khomun.seiyuu.arr_kyaragame, function(ref) {
                      var k = ref[0]
                      var n = ref[1]
                      var gg = ref[2]
                      return _c(
                        "div",
                        { attrs: { align: "left" } },
                        [
                          _c(
                            "v-container",
                            [
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href: "/tualakhon/" + k.id,
                                    "data-method": "get"
                                  }
                                },
                                [_vm._v(_vm._s(k.th) + " ~ " + _vm._s(k.namae))]
                              ),
                              _vm._v(" (" + _vm._s(n) + ")"),
                              _c("br"),
                              _vm._v(" "),
                              _vm._l(gg, function(g, i) {
                                return _c("span", [
                                  i == 0
                                    ? _c("span", [_vm._v("จาก ")])
                                    : _c("span", [_vm._v(", ")]),
                                  _vm._v(" "),
                                  _c(
                                    "a",
                                    {
                                      attrs: {
                                        href:
                                          "/zenigame/" +
                                          encodeURIComponent(g.namae),
                                        "data-method": "get"
                                      }
                                    },
                                    [
                                      _vm._v(_vm._s(g.namae) + " /"),
                                      g.namae != g.romaji
                                        ? _c("span", [
                                            _vm._v(" " + _vm._s(g.romaji))
                                          ])
                                        : _vm._e()
                                    ]
                                  )
                                ])
                              })
                            ],
                            2
                          )
                        ],
                        1
                      )
                    }),
                    0
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("div", { staticClass: "title font-weight-bold" }, [
        _vm._v("วลีเด็ดทั้งหมดจากผลงานพากย์")
      ]),
      _vm._v(" "),
      _vm.khomun.seiyuu.arr_walidetanime.length > 0
        ? _c(
            "div",
            [
              _c(
                "div",
                {
                  staticClass: "title font-weight-bold",
                  attrs: { align: "left" }
                },
                [_vm._v("จากอนิเมะ")]
              ),
              _vm._v(" "),
              _c("kropruamwalidet", {
                attrs: { walidet: _vm.khomun.seiyuu.arr_walidetanime }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _vm.khomun.seiyuu.arr_walidetgame.length > 0
        ? _c(
            "div",
            [
              _c(
                "div",
                {
                  staticClass: "title font-weight-bold",
                  attrs: { align: "left" }
                },
                [_vm._v("จากเกม")]
              ),
              _vm._v(" "),
              _c("kropruamwalidet", {
                attrs: { walidet: _vm.khomun.seiyuu.arr_walidetgame }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("v-container", {
        staticClass: "fb-like",
        attrs: {
          "data-share": "true",
          "data-width": "450",
          "data-show-faces": "true"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }