var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c("v-data-iterator", {
        attrs: {
          items: _vm.arr_seiyuu,
          "items-per-page": _vm.nala,
          page: _vm.nathi,
          "hide-default-footer": ""
        },
        on: {
          "update:itemsPerPage": function($event) {
            _vm.nala = $event
          },
          "update:items-per-page": function($event) {
            _vm.nala = $event
          }
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(p) {
              return [
                _c("div", { staticClass: "title" }, [
                  _c("span", { staticClass: "font-weight-bold" }, [
                    _vm._v("รายชื่อนักพากย์ที่มีข้อมูลวลีเด็ด")
                  ]),
                  _vm._v(
                    " (แสดงชื่อที่ " +
                      _vm._s(_vm.i_roem) +
                      " ถึง " +
                      _vm._s(_vm.i_chop) +
                      " จากทั้งหมด " +
                      _vm._s(_vm.arr_seiyuu.length) +
                      ")\n      "
                  )
                ]),
                _vm._v(" "),
                _c(
                  "v-card-title",
                  { staticClass: "title d-flex justify-space-around" },
                  [
                    _c("span", { staticClass: "d-flex" }, [
                      _c(
                        "span",
                        { staticClass: "subtitle-1" },
                        [
                          _vm._v("แสดงหน้าละ\n            "),
                          _c("v-text-field", {
                            staticStyle: { width: "120px" },
                            attrs: {
                              min: "5",
                              max: _vm.arr_seiyuu.length,
                              type: "number",
                              filled: "",
                              outlined: "",
                              dense: ""
                            },
                            model: {
                              value: _vm.nala,
                              callback: function($$v) {
                                _vm.nala = _vm._n($$v)
                              },
                              expression: "nala"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        { staticClass: "subtitle-1" },
                        [
                          _vm._v("หน้าที่\n            "),
                          _c("v-text-field", {
                            staticStyle: { width: "80px" },
                            attrs: {
                              min: "1",
                              max: parseInt(
                                _vm.arr_seiyuu.length / _vm.nala + 1
                              ),
                              type: "number",
                              filled: "",
                              outlined: "",
                              dense: ""
                            },
                            model: {
                              value: _vm.nathi,
                              callback: function($$v) {
                                _vm.nathi = _vm._n($$v)
                              },
                              expression: "nathi"
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "title flex-column d-flex" }, [
                      _vm._v("\n          ค้นคำ\n          "),
                      _c("span", { staticClass: "d-flex subtitle-1" }, [
                        _c(
                          "span",
                          [
                            _vm._v("ญี่ปุ่น "),
                            _c("v-text-field", {
                              staticStyle: { width: "160px" },
                              attrs: { filled: "", outlined: "", dense: "" },
                              model: {
                                value: _vm.khamkhon_nh,
                                callback: function($$v) {
                                  _vm.khamkhon_nh = $$v
                                },
                                expression: "khamkhon_nh"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "span",
                          [
                            _vm._v("ไทย "),
                            _c("v-text-field", {
                              staticStyle: { width: "160px" },
                              attrs: { filled: "", outlined: "", dense: "" },
                              model: {
                                value: _vm.khamkhon_th,
                                callback: function($$v) {
                                  _vm.khamkhon_th = $$v
                                },
                                expression: "khamkhon_th"
                              }
                            })
                          ],
                          1
                        )
                      ])
                    ])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "v-row",
                  _vm._l(_vm.arr_withiriang, function(c) {
                    return _c("v-col", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.withiriang,
                            expression: "withiriang"
                          }
                        ],
                        key: c[0],
                        attrs: { type: "radio" },
                        domProps: {
                          value: c[0],
                          checked: _vm._q(_vm.withiriang, c[0])
                        },
                        on: {
                          change: function($event) {
                            _vm.withiriang = c[0]
                          }
                        }
                      }),
                      _vm._v(" " + _vm._s(c[1]) + "\n        ")
                    ])
                  }),
                  1
                ),
                _vm._v(" "),
                _c(
                  "v-row",
                  [
                    _c("v-col", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.riangklap,
                            expression: "riangklap"
                          }
                        ],
                        attrs: { type: "checkbox" },
                        domProps: {
                          checked: Array.isArray(_vm.riangklap)
                            ? _vm._i(_vm.riangklap, null) > -1
                            : _vm.riangklap
                        },
                        on: {
                          change: function($event) {
                            var $$a = _vm.riangklap,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 && (_vm.riangklap = $$a.concat([$$v]))
                              } else {
                                $$i > -1 &&
                                  (_vm.riangklap = $$a
                                    .slice(0, $$i)
                                    .concat($$a.slice($$i + 1)))
                              }
                            } else {
                              _vm.riangklap = $$c
                            }
                          }
                        }
                      }),
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            {
                              1: "เรียงจากหลัง",
                              2: "เรียงจากหลัง",
                              3: "เรียงจากอายุมาก",
                              4: "เรียงจากน้อย"
                            }[_vm.withiriang]
                          ) +
                          "\n        "
                      )
                    ]),
                    _vm._v(" "),
                    _c("v-col", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.aochaphothimiwankoet,
                            expression: "aochaphothimiwankoet"
                          }
                        ],
                        attrs: {
                          type: "checkbox",
                          disabled: _vm.withiriang == 3
                        },
                        domProps: {
                          checked: Array.isArray(_vm.aochaphothimiwankoet)
                            ? _vm._i(_vm.aochaphothimiwankoet, null) > -1
                            : _vm.aochaphothimiwankoet
                        },
                        on: {
                          change: function($event) {
                            var $$a = _vm.aochaphothimiwankoet,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  (_vm.aochaphothimiwankoet = $$a.concat([$$v]))
                              } else {
                                $$i > -1 &&
                                  (_vm.aochaphothimiwankoet = $$a
                                    .slice(0, $$i)
                                    .concat($$a.slice($$i + 1)))
                              }
                            } else {
                              _vm.aochaphothimiwankoet = $$c
                            }
                          }
                        }
                      }),
                      _vm._v(" เอาเฉพาะที่มีวันเกิด\n        ")
                    ]),
                    _vm._v(" "),
                    _c("v-col", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.maiaochuerong,
                            expression: "maiaochuerong"
                          }
                        ],
                        attrs: { type: "checkbox" },
                        domProps: {
                          checked: Array.isArray(_vm.maiaochuerong)
                            ? _vm._i(_vm.maiaochuerong, null) > -1
                            : _vm.maiaochuerong
                        },
                        on: {
                          change: function($event) {
                            var $$a = _vm.maiaochuerong,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  (_vm.maiaochuerong = $$a.concat([$$v]))
                              } else {
                                $$i > -1 &&
                                  (_vm.maiaochuerong = $$a
                                    .slice(0, $$i)
                                    .concat($$a.slice($$i + 1)))
                              }
                            } else {
                              _vm.maiaochuerong = $$c
                            }
                          }
                        }
                      }),
                      _vm._v(" เอาแค่ชื่อหลัก\n        ")
                    ])
                  ],
                  1
                ),
                _vm._v(" "),
                _vm._l(p.items, function(s) {
                  return _c(
                    "div",
                    {
                      staticStyle: { margin: "5px" },
                      attrs: { align: "left" }
                    },
                    [
                      s
                        ? _c("div", [
                            _c(
                              "div",
                              { staticClass: "d-flex justify-start title" },
                              [
                                s.kanren
                                  ? _c("span", [_vm._v("~* ")])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.withiriang == 3
                                  ? _c("span", [
                                      _vm._v(
                                        "[" +
                                          _vm._s(
                                            _vm.dueanpi(s.sngp, true, true)
                                          ) +
                                          "] "
                                      )
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      href: "/seiyuu/" + s.namae,
                                      "data-method": "get"
                                    }
                                  },
                                  [
                                    _vm._v(_vm._s(s.th) + " "),
                                    s.namae != s.th
                                      ? _c("span", [
                                          _vm._v("~ " + _vm._s(s.namae))
                                        ])
                                      : _vm._e()
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    staticClass: "subtitle-1",
                                    staticStyle: { color: "#752" }
                                  },
                                  [
                                    _vm._v(
                                      " (" +
                                        _vm._s(s.n_walidet) +
                                        " = อนิเมะ " +
                                        _vm._s(s.n_walidetanime) +
                                        " + เกม " +
                                        _vm._s(s.n_walidetgame) +
                                        ")"
                                    )
                                  ]
                                )
                              ]
                            )
                          ])
                        : _c("div", { staticClass: "title" }, [
                            _vm._v(
                              "\n          -- ไม่พบคำที่ค้นหา --\n        "
                            )
                          ])
                    ]
                  )
                })
              ]
            }
          }
        ])
      }),
      _vm._v(" "),
      _c("v-container", {
        staticClass: "fb-like",
        attrs: {
          "data-share": "true",
          "data-width": "450",
          "data-show-faces": "true"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }