var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c("v-data-iterator", {
        attrs: {
          items: _vm.arr_anime,
          "items-per-page": _vm.nala,
          page: _vm.nathi,
          "hide-default-footer": ""
        },
        on: {
          "update:itemsPerPage": function($event) {
            _vm.nala = $event
          },
          "update:items-per-page": function($event) {
            _vm.nala = $event
          }
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(p) {
              return [
                _c("div", { staticClass: "title" }, [
                  _c("span", { staticClass: "font-weight-bold" }, [
                    _vm._v("รายชื่ออนิเมะที่มีข้อมูลวลีเด็ด")
                  ]),
                  _vm._v(
                    " (แสดงเรื่องที่ " +
                      _vm._s(_vm.i_roem) +
                      " ถึง " +
                      _vm._s(_vm.i_chop) +
                      " จากทั้งหมด " +
                      _vm._s(_vm.arr_anime.length) +
                      ")\n      "
                  )
                ]),
                _vm._v(" "),
                _c("v-card-title", [
                  _c("table", [
                    _c("tr", { attrs: { dense: "" } }, [
                      _c(
                        "td",
                        { staticClass: "subtitle-1" },
                        [
                          _vm._v("แสดงหน้าละ\n              "),
                          _c("v-text-field", {
                            staticStyle: { width: "120px" },
                            attrs: {
                              min: "5",
                              max: _vm.arr_anime.length,
                              type: "number",
                              filled: "",
                              outlined: "",
                              dense: ""
                            },
                            model: {
                              value: _vm.nala,
                              callback: function($$v) {
                                _vm.nala = _vm._n($$v)
                              },
                              expression: "nala"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "td",
                        { staticClass: "subtitle-1" },
                        [
                          _vm._v("หน้าที่\n              "),
                          _c("v-text-field", {
                            staticStyle: { width: "80px" },
                            attrs: {
                              min: "1",
                              max: parseInt(
                                _vm.arr_anime.length / _vm.nala + 1
                              ),
                              type: "number",
                              filled: "",
                              outlined: "",
                              dense: ""
                            },
                            model: {
                              value: _vm.nathi,
                              callback: function($$v) {
                                _vm.nathi = _vm._n($$v)
                              },
                              expression: "nathi"
                            }
                          })
                        ],
                        1
                      )
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c(
                  "v-row",
                  { attrs: { dense: "" } },
                  [
                    _vm._l(_vm.arr_withiriang, function(c) {
                      return _c("v-col", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.withiriang,
                              expression: "withiriang"
                            }
                          ],
                          key: c[0],
                          attrs: { type: "radio" },
                          domProps: {
                            value: c[0],
                            checked: _vm._q(_vm.withiriang, c[0])
                          },
                          on: {
                            change: function($event) {
                              _vm.withiriang = c[0]
                            }
                          }
                        }),
                        _vm._v(" " + _vm._s(c[1]) + "\n        ")
                      ])
                    }),
                    _vm._v(" "),
                    _c("v-col", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.riangklap,
                            expression: "riangklap"
                          }
                        ],
                        attrs: { type: "checkbox" },
                        domProps: {
                          checked: Array.isArray(_vm.riangklap)
                            ? _vm._i(_vm.riangklap, null) > -1
                            : _vm.riangklap
                        },
                        on: {
                          change: function($event) {
                            var $$a = _vm.riangklap,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 && (_vm.riangklap = $$a.concat([$$v]))
                              } else {
                                $$i > -1 &&
                                  (_vm.riangklap = $$a
                                    .slice(0, $$i)
                                    .concat($$a.slice($$i + 1)))
                              }
                            } else {
                              _vm.riangklap = $$c
                            }
                          }
                        }
                      }),
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            {
                              1: "เรียงจากหลัง",
                              2: "เรียงจากหลัง",
                              3: "เรียงจากเก่า",
                              4: "เรียงจากน้อย"
                            }[_vm.withiriang]
                          ) +
                          "\n        "
                      )
                    ])
                  ],
                  2
                ),
                _vm._v(" "),
                _vm._l(p.items, function(a) {
                  return _c(
                    "div",
                    {
                      staticStyle: { margin: "5px" },
                      attrs: { align: "left" }
                    },
                    [
                      _c(
                        "v-container",
                        { staticClass: "d-flex justify-start" },
                        [
                          _vm.withiriang == 3
                            ? _c("span", [
                                _vm._v(
                                  "[" + _vm._s(_vm.dueanpi(a.ngp, true)) + "] "
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "../anime/" + encodeURIComponent(a.namae),
                                "data-method": "get"
                              }
                            },
                            [
                              _vm._v(" " + _vm._s(a.namae) + "\n          "),
                              a.namae != a.romaji
                                ? _c("span", [_vm._v(" / " + _vm._s(a.romaji))])
                                : _vm._e()
                            ]
                          ),
                          _vm._v(" "),
                          _c("span", { staticStyle: { color: "#752" } }, [
                            _vm._v(" (" + _vm._s(a.n_walidet) + ")")
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c("v-divider")
                    ],
                    1
                  )
                })
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }