var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "a",
        {
          attrs: {
            href: "https://www.facebook.com/" + _vm.walidet.lek,
            target: "_blank"
          }
        },
        [
          _c("v-img", {
            attrs: {
              src:
                "https://hinaboshi.com/rup/rupprakopwalidet/" +
                _vm.walidet.lek +
                ".jpg",
              "max-width": "540px"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("v-simple-table", [
        _c("tbody", [
          _c("tr", [
            _c(
              "td",
              {
                staticClass: "subtitle-1",
                staticStyle: { border: "1px #4A40FF solid" }
              },
              [_vm._v("วลีเด็ด")]
            ),
            _vm._v(" "),
            _c(
              "td",
              {
                staticClass: "subtitle-1",
                staticStyle: { border: "1px #4A40FF solid" }
              },
              [_vm._v(_vm._s(_vm.walidet.wali_nh))]
            )
          ]),
          _vm._v(" "),
          _c("tr", [
            _c(
              "td",
              {
                staticClass: "subtitle-1",
                staticStyle: { border: "1px #4A40FF solid" }
              },
              [_vm._v("คำแปล")]
            ),
            _vm._v(" "),
            _c(
              "td",
              {
                staticClass: "subtitle-1",
                staticStyle: { border: "1px #4A40FF solid" }
              },
              [_vm._v(_vm._s(_vm.walidet.wali_th))]
            )
          ]),
          _vm._v(" "),
          _c("tr", [
            _c(
              "td",
              {
                staticClass: "subtitle-1",
                staticStyle: { border: "1px #4A40FF solid" }
              },
              [_vm._v("romaji")]
            ),
            _vm._v(" "),
            _c(
              "td",
              {
                staticClass: "subtitle-1",
                staticStyle: { border: "1px #4A40FF solid" }
              },
              [_vm._v(_vm._s(_vm.walidet.wali_ro))]
            )
          ]),
          _vm._v(" "),
          _vm.khomun.mode == "a"
            ? _c("tr", [
                _c(
                  "td",
                  {
                    staticClass: "subtitle-1",
                    staticStyle: { border: "1px #4A40FF solid" }
                  },
                  [_vm._v("จากเรื่อง")]
                ),
                _vm._v(" "),
                _c(
                  "td",
                  {
                    directives: [
                      {
                        name: "ripple",
                        rawName: "v-ripple",
                        value: { class: "primary--text" },
                        expression: "{ class: 'primary--text' }"
                      }
                    ],
                    staticClass: "subtitle-1",
                    staticStyle: { border: "1px #4A40FF solid" }
                  },
                  [
                    _c(
                      "a",
                      {
                        attrs: {
                          href: "/anime/" + encodeURIComponent(_vm.anime.namae),
                          "data-method": "get"
                        }
                      },
                      [
                        _vm._v(_vm._s(_vm.walidet.anime_n) + "\n          "),
                        _c("br"),
                        _vm._v(_vm._s(_vm.anime.romaji))
                      ]
                    )
                  ]
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.walidet.ton
            ? _c("tr", [
                _c(
                  "td",
                  {
                    staticClass: "subtitle-1",
                    staticStyle: { border: "1px #4A40FF solid" }
                  },
                  [_vm._v("ตอน")]
                ),
                _vm._v(" "),
                _c(
                  "td",
                  {
                    staticClass: "subtitle-1",
                    staticStyle: { border: "1px #4A40FF solid" }
                  },
                  [_vm._v(_vm._s(_vm.walidet.ton))]
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.khomun.mode == "g"
            ? _c("tr", [
                _c(
                  "td",
                  {
                    staticClass: "subtitle-1",
                    staticStyle: { border: "1px #4A40FF solid" }
                  },
                  [_vm._v("จากเกม")]
                ),
                _vm._v(" "),
                _c(
                  "td",
                  {
                    directives: [
                      {
                        name: "ripple",
                        rawName: "v-ripple",
                        value: { class: "primary--text" },
                        expression: "{ class: 'primary--text' }"
                      }
                    ],
                    staticClass: "subtitle-1",
                    staticStyle: { border: "1px #4A40FF solid" }
                  },
                  [
                    _c(
                      "a",
                      {
                        attrs: {
                          href:
                            "/zenigame/" + encodeURIComponent(_vm.game.namae),
                          "data-method": "get"
                        }
                      },
                      [
                        _vm._v(_vm._s(_vm.walidet.game_n) + "\n          "),
                        _c("br"),
                        _vm._v(_vm._s(_vm.game.romaji))
                      ]
                    )
                  ]
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.khomun.mode == "k"
            ? _c("tr", [
                _c(
                  "td",
                  {
                    staticClass: "subtitle-1",
                    staticStyle: { border: "1px #4A40FF solid" }
                  },
                  [_vm._v("กล่าวโดย")]
                ),
                _vm._v(" "),
                _c(
                  "td",
                  {
                    directives: [
                      {
                        name: "ripple",
                        rawName: "v-ripple",
                        value: { class: "primary--text" },
                        expression: "{ class: 'primary--text' }"
                      }
                    ],
                    staticClass: "subtitle-1",
                    staticStyle: { border: "1px #4A40FF solid" }
                  },
                  [
                    _c(
                      "a",
                      {
                        attrs: {
                          href: "/khondang/" + _vm.khondang.namae,
                          "data-method": "get"
                        }
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.khondang.th) +
                            "\n          "
                        ),
                        _c("br"),
                        _vm._v(_vm._s(_vm.khondang.namae))
                      ]
                    )
                  ]
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          "ag".includes(_vm.khomun.mode) && _vm.doi
            ? _c("tr", [
                _c(
                  "td",
                  {
                    staticClass: "subtitle-1",
                    staticStyle: { border: "1px #4A40FF solid" }
                  },
                  [_vm._v("กล่าวโดย")]
                ),
                _vm._v(" "),
                _c(
                  "td",
                  {
                    directives: [
                      {
                        name: "ripple",
                        rawName: "v-ripple",
                        value: { class: "primary--text" },
                        expression: "{ class: 'primary--text' }"
                      }
                    ],
                    staticClass: "subtitle-1",
                    staticStyle: { border: "1px #4A40FF solid" }
                  },
                  [
                    _c(
                      "a",
                      {
                        attrs: {
                          href: "/tualakhon/" + _vm.doi.id,
                          "data-method": "get"
                        }
                      },
                      [
                        _vm._v(_vm._s(_vm.doi.th) + "\n          "),
                        _c("br"),
                        _vm._v(_vm._s(_vm.doi.namae) + "\n          "),
                        _vm.doi.hoka
                          ? _c("span", [_c("br"), _vm._v(_vm._s(_vm.doi.hoka))])
                          : _vm._e()
                      ]
                    )
                  ]
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          "ag".includes(_vm.khomun.mode) && _vm.seiyuu
            ? _c("tr", [
                _c(
                  "td",
                  {
                    staticClass: "subtitle-1",
                    staticStyle: { border: "1px #4A40FF solid" }
                  },
                  [_vm._v("พากย์โดย")]
                ),
                _vm._v(" "),
                _c(
                  "td",
                  {
                    directives: [
                      {
                        name: "ripple",
                        rawName: "v-ripple",
                        value: { class: "primary--text" },
                        expression: "{ class: 'primary--text' }"
                      }
                    ],
                    staticClass: "subtitle-1",
                    staticStyle: { border: "1px #4A40FF solid" }
                  },
                  [
                    _c(
                      "a",
                      {
                        attrs: {
                          href: "/seiyuu/" + _vm.seiyuu.namae,
                          "data-method": "get"
                        }
                      },
                      [
                        _vm._v(_vm._s(_vm.seiyuu.th) + "\n          "),
                        _c("br"),
                        _vm._v(_vm._s(_vm.seiyuu.namae))
                      ]
                    )
                  ]
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("tr", [
            _c("td", {
              staticClass: "subtitle-1",
              staticStyle: { border: "1px #4A40FF solid" }
            }),
            _vm._v(" "),
            _c(
              "td",
              {
                directives: [
                  {
                    name: "ripple",
                    rawName: "v-ripple",
                    value: { class: "primary--text" },
                    expression: "{ class: 'primary--text' }"
                  }
                ],
                staticClass: "subtitle-1",
                staticStyle: { border: "1px #4A40FF solid" }
              },
              [
                _c(
                  "a",
                  {
                    attrs: {
                      href: "https://www.facebook.com/" + _vm.walidet.lek
                    }
                  },
                  [_vm._v("ดูในหน้า facebook")]
                )
              ]
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _c("v-container", {
        staticClass: "fb-like",
        attrs: {
          "data-share": "true",
          "data-width": "450",
          "data-show-faces": "true"
        }
      }),
      _vm._v(" "),
      _c("div", { attrs: { align: "left" } }, [
        _vm._v("\n    *บางวลีเด็ดอาจมีรายละเอียดเพิ่มเติมในหน้า facebook"),
        _c("br"),
        _vm._v("\n    *คลิกที่ชื่อบุคคลเพื่อดูวลีเด็ดจากคนเดียวกัน \n  ")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "title" }, [
        _c(
          "a",
          {
            attrs: {
              href: "/walidet/" + _vm.khomun.lek_w_kon,
              "data-method": "get"
            }
          },
          [_vm._v("《ดูวลีเด็ดก่อนหน้า")]
        ),
        _vm._v(" 〜〜〜\n    "),
        _c(
          "a",
          {
            attrs: {
              href: "/walidet/" + _vm.khomun.lek_w_lang,
              "data-method": "get"
            }
          },
          [_vm._v("ดูวลีเด็ดถัดไป》")]
        )
      ]),
      _vm._v(" "),
      _c(
        "v-btn",
        {
          staticClass: "title",
          attrs: {
            href: "/walidet/" + _vm.khomun.lek_w_sum,
            "data-method": "get"
          }
        },
        [_vm._v("สุ่มวลีเด็ดใหม่")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }