var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c("div", { staticClass: "headline font-weight-bold" }, [
        _vm._v("\n    " + _vm._s(_vm.khomun.kyara.th)),
        _c("br"),
        _vm._v(_vm._s(_vm.khomun.kyara.namae) + "\n  ")
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "title font-weight-bold", attrs: { align: "left" } },
        [_vm._v("ข้อมูลตัวละคร")]
      ),
      _vm._v(" "),
      _c(
        "v-card",
        { staticStyle: { border: "solid #999 3px" }, attrs: { shaped: "" } },
        [
          _c("v-container", [
            _c("table", { staticClass: "title" }, [
              _c("tr", [
                _c("td", [_vm._v("ชื่อญี่ปุ่น :")]),
                _c("td", [_vm._v(_vm._s(_vm.khomun.kyara.namae))])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("ชื่อไทย :")]),
                _c("td", [_vm._v(_vm._s(_vm.khomun.kyara.th))])
              ]),
              _vm._v(" "),
              _vm.khomun.kyara.hoka
                ? _c("tr", [
                    _c("td", [_vm._v("อื่นๆ :")]),
                    _c("td", [_vm._v(_vm._s(_vm.khomun.kyara.hoka))])
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("มีวลีเด็ดทั้งหมด :")]),
                _c("td", { staticStyle: { color: "#722" } }, [
                  _vm._v(_vm._s(_vm.khomun.kyara.arr_walidet.length))
                ])
              ]),
              _vm._v(" "),
              _vm.khomun.kyara.anime.length > 0
                ? _c("tr", [
                    _c("td", [_vm._v("จากอนิเมะ :")]),
                    _vm._v(" "),
                    _c(
                      "td",
                      _vm._l(_vm.khomun.kyara.anime, function(a) {
                        return _c("div", [
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "/anime/" + encodeURIComponent(a.namae),
                                "data-method": "get"
                              }
                            },
                            [
                              _vm._v(_vm._s(a.namae) + " "),
                              a.namae != a.romaji
                                ? _c("span", [_vm._v("/ " + _vm._s(a.romaji))])
                                : _vm._e()
                            ]
                          )
                        ])
                      }),
                      0
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.khomun.kyara.game.length > 0
                ? _c("tr", [
                    _c("td", [_vm._v("จากเกม :")]),
                    _vm._v(" "),
                    _c(
                      "td",
                      _vm._l(_vm.khomun.kyara.game, function(g) {
                        return _c("div", [
                          _c(
                            "a",
                            {
                              attrs: {
                                href:
                                  "/zenigame/" + encodeURIComponent(g.namae),
                                "data-method": "get"
                              }
                            },
                            [
                              _vm._v(_vm._s(g.namae) + " "),
                              g.namae != g.romaji
                                ? _c("span", [_vm._v("/ " + _vm._s(g.romaji))])
                                : _vm._e()
                            ]
                          )
                        ])
                      }),
                      0
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.khomun.kyara.seiyuu.length > 0
                ? _c("tr", [
                    _c("td", [_vm._v("นักพากย์ :")]),
                    _vm._v(" "),
                    _c(
                      "td",
                      _vm._l(_vm.khomun.kyara.seiyuu, function(s) {
                        return _c("div", [
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "/seiyuu/" + s.namae,
                                "data-method": "get"
                              }
                            },
                            [_vm._v(_vm._s(s.th) + " ~ " + _vm._s(s.namae))]
                          )
                        ])
                      }),
                      0
                    )
                  ])
                : _vm._e()
            ])
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c("riangphapwalidet", {
        attrs: { walidet: _vm.khomun.kyara.arr_walidet }
      }),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("div", { staticClass: "title font-weight-bold" }, [
        _vm._v("วลีเด็ดทั้งหมดจากตัวละครนี้")
      ]),
      _vm._v(" "),
      _vm.khomun.kyara.arr_walidet.length > 0
        ? _c(
            "div",
            [
              _c("kropruamwalidet", {
                attrs: { walidet: _vm.khomun.kyara.arr_walidet }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }