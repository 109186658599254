<template>
  <div>
    <div class="title">หน้าจัดการ</div>
  </div>
</template>

<script>
export default {
  props: {
    khomun: Object,
  },
  data(){
    return {
      
    }
  },
  computed: {
    
  },
  methods: {

  },
  created(){
    
  }
}
</script>