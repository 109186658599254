let duean = ["","มกราคม","กุมภาพันธ์","มีนาคม","เมษายน","พฤษภาคม","มิถุนายน","กรกฎาคม","สิงหาคม","กันยายน","ตุลาคม","พฤศจิกายน","ธันวาคม"];
let dueanyo = ["","ม.ค.","ก.พ.","มี.ค.","เม.ย.","พ.ค.","มิ.ย.","ก.ค.","ก.ค.","ก.ย.","ต.ค.","พ.ย.","ธ.ค."];

export default (pdw,yo=false,wan=false) => {
  pdw = pdw.split("-");
  let pd
  if(yo) pd = dueanyo[parseInt(pdw[1])]+" "+pdw[0];
  else pd = duean[parseInt(pdw[1])]+" "+pdw[0];
  if(wan) return parseInt(pdw[2]) + " " + pd
  else return pd
}