var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.khomun.miphakto
        ? _c("div", [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.ruam_phakto,
                  expression: "ruam_phakto"
                }
              ],
              attrs: { type: "checkbox" },
              domProps: {
                checked: Array.isArray(_vm.ruam_phakto)
                  ? _vm._i(_vm.ruam_phakto, null) > -1
                  : _vm.ruam_phakto
              },
              on: {
                change: function($event) {
                  var $$a = _vm.ruam_phakto,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 && (_vm.ruam_phakto = $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        (_vm.ruam_phakto = $$a
                          .slice(0, $$i)
                          .concat($$a.slice($$i + 1)))
                    }
                  } else {
                    _vm.ruam_phakto = $$c
                  }
                }
              }
            }),
            _vm._v(" รวมภาคต่อ")
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-card",
        { staticStyle: { border: "solid #999 3px" }, attrs: { shaped: "" } },
        [
          _c(
            "v-container",
            { staticStyle: { margin: "15px" } },
            _vm._l(_vm.kyara_n_seiyuu_thimi, function(ref, i) {
              var k = ref[0]
              var n = ref[1]
              var ss = ref[2]
              return _c(
                "div",
                { staticStyle: { margin: "8px" }, attrs: { align: "left" } },
                [
                  _c("strong", [
                    _c(
                      "a",
                      {
                        attrs: {
                          href: "/tualakhon/" + k.id,
                          "data-method": "get"
                        }
                      },
                      [
                        _vm._v(_vm._s(k.th) + " ~ " + _vm._s(k.namae)),
                        k.hoka
                          ? _c("span", [_vm._v(" ~ " + _vm._s(k.hoka))])
                          : _vm._e()
                      ]
                    )
                  ]),
                  _vm._v(" (" + _vm._s(n) + ")\n        "),
                  _vm._l(ss, function(s, i) {
                    return _c("span", [
                      s
                        ? _c("span", [
                            i == 0
                              ? _c("span", [_c("br"), _vm._v("พากย์โดย ")])
                              : _c("span", [_vm._v(", ")]),
                            _vm._v(" "),
                            _c(
                              "a",
                              {
                                attrs: {
                                  href: "/seiyuu/" + s.namae,
                                  "data-method": "get"
                                }
                              },
                              [_vm._v(_vm._s(s.th) + " ~ " + _vm._s(s.namae))]
                            )
                          ])
                        : _vm._e()
                    ])
                  })
                ],
                2
              )
            }),
            0
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }