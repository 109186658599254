<template>
  <div>
    <div v-if="khomun.miphakto"><input type="checkbox" v-model="ruam_phakto"> รวมภาคต่อ</div>
    <v-card shaped style="border: solid #999 3px">
      <v-container style="margin: 15px">
        <div v-for="([k,n,ss],i) in kyara_n_seiyuu_thimi" align="left" style="margin: 8px">
          <strong><a :href="'/tualakhon/'+k.id" data-method="get">{{k.th}} ~ {{k.namae}}<span v-if="k.hoka"> ~ {{k.hoka}}</span></a></strong> ({{n}})
          <span v-for="(s,i) in ss">
            <span v-if="s">
              <span v-if="i==0"><br>พากย์โดย </span>
              <span v-else>, </span>
              <a :href="'/seiyuu/'+s.namae" data-method="get">{{s.th}} ~ {{s.namae}}</a>
            </span>
          </span>
        </div>
      </v-container>
    </v-card>
  </div>
</template>

<script>
// กรอบแสดงตัวละครและนักพากย์ในหน้าอนิเมะหรือเกม
export default {
  props: {
    khomun: Object,
  },
  data() {
    return {
      ruam_phakto: false // รวมภาคต่อหรือไม่
    }
  },
  computed: {
    kyara_n_seiyuu_thimi(){ // ข้อมูลที่จะแสดง โดยพิจารณาดูแล้วว่าเอาพวกภาคต่อด้วยหรือไม่
      if(this.ruam_phakto) return this.khomun.kyara_n_seiyuu_ruamphakto;
      else return this.khomun.kyara_n_seiyuu;
    }
  }
}
</script>