<template>
  <v-app-bar app color="#f9c8c8" hide-on-scroll class="d-flex justify-space-around">
    <v-card v-for="h in huakho" style="background: #def">
      <v-container class="subtitle-1">
        <a :href="h.link" data-method="get">{{h.chue}}</a>
      </v-container>
    </v-card>
  </v-app-bar>
</template>

<script>
export default {
  data: function () {
    return {
      huakho: [
        {chue: "เพิ่มวลีเด็ด",link: "/ami/saiwalidet"},
        {chue: "เพิ่มรูป",link: "/ami/sairup"},
        {chue: "แก้อนิเมะ",link: "/ami/laikhomun/anime"},
        {chue: "แก้เกม",link: "/ami/laikhomun/game"},
        {chue: "แก้คนดัง",link: "/ami/laikhomun/khondang"},
        {chue: "แก้ตัวละคร",link: "/ami/laikhomun/kyara"},
        {chue: "แก้นักพากย์",link: "/ami/laikhomun/seiyuu"},
        {chue: "แก้วลีเด็ดอนิเมะ",link: "/ami/laikhomun/walidetanime"},
        {chue: "แก้วลีเด็ดเกม",link: "/ami/laikhomun/walidetgame"},
        {chue: "แก้วลีเด็ดคนดัง",link: "/ami/laikhomun/walidetkhondang"},
        {chue: "กลับหน้าวลีเด็ด",link: "/"},
        {chue: "ออกจากระบบ",link: "/ami/logout"}
      ]
    }
  }
}
</script>