var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c("div", { staticClass: "headline font-weight-bold" }, [
        _vm._v("\n    " + _vm._s(_vm.khomun.game.namae) + "\n    "),
        _vm.khomun.game.namae != _vm.khomun.game.romaji
          ? _c("span", [_c("br"), _vm._v(_vm._s(_vm.khomun.game.romaji))])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "title font-weight-bold", attrs: { align: "left" } },
        [_vm._v("ข้อมูลเกม")]
      ),
      _vm._v(" "),
      _c(
        "v-card",
        { staticStyle: { border: "solid #999 3px" }, attrs: { shaped: "" } },
        [
          _c("v-container", [
            _c("table", { staticClass: "title" }, [
              _c("tr", [
                _c("td", [_vm._v("ชื่อเกม :")]),
                _c("td", [_vm._v(_vm._s(_vm.khomun.game.namae))])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("คานะ :")]),
                _c("td", [_vm._v(_vm._s(_vm.khomun.game.kana))])
              ]),
              _vm._v(" "),
              _vm.khomun.game.namae != _vm.khomun.game.romaji
                ? _c("tr", [
                    _c("td", [_vm._v("ชื่อโรมาจิ :")]),
                    _vm._v(_vm._s(_vm.khomun.game.romaji)),
                    _c("td")
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("วางจำหน่าย :")]),
                _vm._v(_vm._s(_vm.dueanpi(_vm.khomun.game.ngp))),
                _c("td")
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td"),
                _c("td", [
                  _c("a", { attrs: { href: _vm.urlwiki, target: "_blank" } }, [
                    _vm._v("wikipedia")
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("มีวลีเด็ดทั้งหมด :")]),
                _c("td", [
                  _vm._v(_vm._s(_vm.khomun.walidet.length)),
                  _vm.khomun.miphakto
                    ? _c("span", [
                        _vm._v(
                          " (รวมภาคต่อเป็น " +
                            _vm._s(
                              _vm.khomun.walidet.length +
                                _vm.khomun.walidet_phakto.length
                            ) +
                            ")"
                        )
                      ])
                    : _vm._e()
                ])
              ]),
              _vm._v(" "),
              _vm.khomun.miphakto
                ? _c("tr", [
                    _c("td", [_vm._v("ภาคต่อ :")]),
                    _vm._v(" "),
                    _c(
                      "td",
                      _vm._l(_vm.khomun.arr_phakto, function(g) {
                        return _c("div", [
                          _c(
                            "a",
                            {
                              attrs: {
                                href:
                                  "/zenigame/" + encodeURIComponent(g.namae),
                                "data-method": "get"
                              }
                            },
                            [
                              _vm._v(_vm._s(g.namae) + " "),
                              g.namae != g.romaji
                                ? _c("span", [_vm._v("/ " + _vm._s(g.romaji))])
                                : _vm._e()
                            ]
                          )
                        ])
                      }),
                      0
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.khomun.pkaklak
                ? _c("tr", [
                    _c("td", [_vm._v("เป็นภาคต่อของ: ")]),
                    _c("td", [
                      _c(
                        "a",
                        {
                          attrs: {
                            href:
                              "/zenigame/" +
                              encodeURIComponent(_vm.khomun.pkaklak.namae),
                            "data-method": "get"
                          }
                        },
                        [
                          _vm._v(_vm._s(_vm.khomun.pkaklak.namae) + " "),
                          _vm.khomun.pkaklak.namae != _vm.khomun.pkaklak.romaji
                            ? _c("span", [
                                _vm._v("/ " + _vm._s(_vm.khomun.pkaklak.romaji))
                              ])
                            : _vm._e()
                        ]
                      )
                    ])
                  ])
                : _vm._e()
            ])
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c("riangphapwalidet", { attrs: { walidet: _vm.khomun.walidet } }),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "title font-weight-bold", attrs: { align: "left" } },
        [_vm._v("ตัวละครและนักพากย์")]
      ),
      _vm._v(" "),
      _c("kropkyaraseiyuu", { attrs: { khomun: _vm.khomun } }),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "title font-weight-bold", attrs: { align: "left" } },
        [_vm._v("วลีเด็ดทั้งหมดจากเกมนี้")]
      ),
      _vm._v(" "),
      _c("kropruamwalidet", {
        attrs: {
          walidet: _vm.khomun.walidet,
          walidet_phakto: _vm.khomun.walidet_phakto,
          miphakto: _vm.khomun.miphakto
        }
      }),
      _vm._v(" "),
      _c("v-container", {
        staticClass: "fb-like",
        attrs: {
          "data-share": "true",
          "data-width": "450",
          "data-show-faces": "true"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }