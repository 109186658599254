var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app-bar",
    {
      staticClass: "d-flex justify-space-around",
      attrs: { app: "", color: "#f9c8c8", "hide-on-scroll": "" }
    },
    _vm._l(_vm.huakho, function(h) {
      return _c(
        "v-card",
        { staticStyle: { background: "#def" } },
        [
          _c("v-container", { staticClass: "subtitle-1" }, [
            _c("a", { attrs: { href: h.link, "data-method": "get" } }, [
              _vm._v(_vm._s(h.chue))
            ])
          ])
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }