<template>
  <div>
    <div class="title">เพิ่มวลีเด็ด</div>
    <textarea name="thangmot" rows="8" v-model="khokhwam" style="width: 100%; border: #00f solid 2px; width: 100%;"></textarea><br>
    <v-btn class="primary" @click="yaeksuan()">ทำการแยกส่วนแล้วยัดใส่ด้านล่าง</v-btn>
    <v-btn class="warning" @click="lopmot()">ลบทั้งหมด</v-btn>
    <br>
    <div v-for="s in sathana" style="color: #1e1" class="title">
      <a :href="s[1]" target="_blank">{{s[0]}}</a>
    </div>
    
    <table border="1" style="width: 100%">
      <tr>
        <td></td>
        <td class="v-flex">
          <span><input type="radio" v-model="muat" value="anime" style="background: #fff;border: #00f solid 2px;"> อนิเมะ</span>
          <span><input type="radio" v-model="muat" value="game" style="background: #fff;border: #00f solid 2px;"> เกม</span>
          <span><input type="radio" v-model="muat" value="khondang" style="background: #fff;border: #00f solid 2px;"> คนดัง</span>
        </td>
      </tr>
      <tr><td>id</td><td><input type="text" style="" v-model="walidet.lek" style="width: 220px; background: #fff;border: #00f solid 2px;"> <a :href="'https://www.facebook.com/'+walidet.lek">facebook</a></td></tr>
      <tr><td>วลีเด็ด</td><td><textarea name="wali_nh" rows="3" v-model="walidet.wali_nh" style="width: 100%; border: #00f solid 2px; width: 100%;"></textarea></td></tr>
      <tr><td>คำแปล</td><td><textarea name="wali_th" rows="3" v-model="walidet.wali_th" style="width: 100%; border: #00f solid 2px; width: 100%;"></textarea></td></tr>
      <tr><td>romaji</td><td><textarea name="wali_ro" rows="3" v-model="walidet.wali_ro" style="width: 100%; border: #00f solid 2px; width: 100%;"></textarea></td></tr>
      <template v-if="muat=='anime'||muat=='game'">
        <tr>
          <td>ชื่ออนิเมะ/เกม</td>
          <td>
            ญี่ปุ่น
            <span v-if="muat=='anime'">
              <input type="text" v-model="walidet.anime_n" style="width: 90%; background: #fff;border: #00f solid 2px;"><br>
              <a :href="'/anime/'+walidet.anime_n">{{walidet.anime_n}}</a>
            </span>
            <span v-if="muat=='game'">
              <input type="text" v-model="walidet.game_n" style="width: 90%; background: #fff; border: #00f solid 2px;"><br>
              <a :href="'/zenigame/'+encodeURIComponent(walidet.game_n)">{{walidet.game_n}}</a>
            </span>
            <br>โรมาจิ <input type="text" v-model="romaji" style="width: 90%; background: #fff; border: #00f solid 2px;">
          </td>
        </tr>
        <tr v-show="muat=='anime'"><td>ตอน</td><td><input type="input" outlined v-model="walidet.ton" style="width: 170px; background: #fff; border: #00f solid 2px;"></td></tr>
        <tr>
          <td>ตัวละคร</td>
          <td>
            หมายเลข <input type="text" style="width: 150px; background: #fff;border: #00f solid 2px;" v-model="walidet.doi_id">
            <a :href="'/tualakhon/'+walidet.doi_id" target="_blank">{{kyara.namae}}</a><br>
            ชื่อญี่ปุ่น <input type="text" style="width: 200px; background: #fff;border: #00f solid 2px;" v-model="kyara.namae"><br>
            ชื่อไทย <input type="text" style="width: 200px; background: #fff;border: #00f solid 2px;" v-model="kyara.th"><br>
            ชื่ออื่นๆ <input type="text" style="width: 200px; background: #fff;border: #00f solid 2px;" v-model="kyara.hoka">
          </td>
        </tr>
        <tr>
          <td>นักพากย์</td>
          <td>
            <input type="text" v-model="walidet.seiyuu_n">
            <a :href="'/seiyuu/'+walidet.seiyuu_n" target="_blank">{{walidet.seiyuu_n}}</a>
          </td>
        </tr>
      </template>
      <template v-else>
        <tr>
          <td>ผู้พูด</td>
          <td>
            ชื่อญี่ปุ่น <input type="text" v-model="walidet.doi_n" style="width: 300px; background: #fff;border: #00f solid 2px;"><br>
            ชื่อไทย <input type="text" v-model="khondang.th" style="width: 300px; background: #fff;border: #00f solid 2px;">
          </td>
        </tr>
        <tr><td>เป็น</td><td><input type="text" v-model="khondang.dare" style="width: 300px; background: #fff;border: #00f solid 2px;"></td></tr>
      </template>
      <tr><td></td><td><v-btn class="primary" @click="banthuek()">บันทึกเพิ่มข้อมูล</v-btn></td></tr>
    </table>
  </div>
</template>

<script>
import axios from 'axios';
import axioscsrf from '../buki/axioscsrf';
axioscsrf(axios);

export default {
  props: {
    khomun: Object,
  },
  data(){
    return {
      khokhwam: "", // ข้อความในกรอบใหญ่ที่จะเอามาแยกส่วน
      muat: "", // หมวด [anime,game,khondang]
      walidet: {}, // ข้อมูลวลีเด็ด
      romaji: "", // ชื่อโรมาจิของอนิเมะหรือเกม
      khondang: {}, // ข้อมูลคนดัง
      kyara: {}, // ข้อมูลตัวละคร
      sathana: [] // ข้อมูลสถานะที่ให้แสดงด้านบน
    }
  },
  computed: {
    
  },
  methods: {
    yaeksuan(){
      this.sathana = []; // ข้อมูลสถานะ
      this.khokhwam.replace(/(.+)\n(.+)\n(.+)\n\n?(.+)\n?(.+)?/,(...s)=>{
        console.log(s);
        this.walidet.wali_nh =  s[1].trim(); // บรรทัดแรก วลีเด็ดภาษาญี่ปุ่น
        this.walidet.wali_th =  s[2].trim(); // บรรทัดที่ 2 คำแปลไทย
        this.walidet.wali_ro =  s[3].trim(); // บรรทัดที่ 3 คำอ่านโรมาจิ

        let s5 = s[5]; // บรรทัดที่ 5 ข้อมูลอนิเมะหรือเกม
        if(s5){
          s5.replace(/จาก([ก-๙]+) ([^ก-๙]+)([ก-๙]+(.+))?/,(...ss)=>{
            console.log(ss);
            if(ss[1].includes("เกม")) this.muat = "game"; // เป็นวลีเด็ดเกม
            else this.muat = "anime"; // เป็นวลีเด็ดอนิเมะ
            if(ss[2].includes("/")){
              let nr = ss[2].split("/")
              // ชื่ออนิเมะหรือเกม
              if(this.muat=="anime") this.walidet.anime_n = nr[0].trim();
              else this.walidet.game_n = nr[0].trim();
              this.romaji = nr[1].trim(); // ชื่อโรมาจิ
            }
            else{
              // ชื่ออนิเมะหรือเกม
              if(this.muat=="anime") this.walidet.anime_n = ss[2].trim();
              else this.walidet.game_n = ss[2].trim();
              this.romaji = ss[2].trim(); // ชื่อโรมาจิ
            }
            if(ss[4]) this.walidet.ton = ss[4].trim(); // ตอนของวลีเด็ด ถ้ามี
          });

          // บรรทัดที่ 4 ตัวละครที่พูด
          s[4].replace(/(.+)\((.+)\)/,(...ss)=>{
            this.kyara.th = ss[1].trim(); // ชื่อภาษาไทย
            if(ss[2].includes(",")) {
              // ถ้ามีชื่อภาษาอื่นด้วย แยกโดย ,
              let nh_hoka = ss[2].split(",");
              this.kyara.namae = nh_hoka[0].trim(); // ชื่อญี่ปุ่น
              this.kyara.hoka = nh_hoka[1].trim(); // ชื่อภาษาอื่น
            }
            else{
              // ถ้ามีแต่ชื่อภาษาญี่ปุ่นกับไทย
              this.kyara.namae = ss[2].trim(); // ชื่อญี่ปุ่น
              this.kyara.hoka = "";
            }
            this.khonkyara(); // ค้นหาตัวละครชื่อนี้ในฐานข้อมูล และค้นนักพากย์ด้วย
          });
        }
        else{ // เป็นวลีเด็ดคนดัง
          this.muat = "khondang"; 
          s[4].replace(/(.+)\((.+)\)(.+)/,(...ss)=>{
            this.walidet.doi_n = ss[2].trim(); // ชื่อญี่ปุ่น
            this.khondang.th = ss[1].trim(); // ชื่อไทย
            this.khondang.dare = ss[3].trim(); // อาชีพ
          });
        }
      });
    },
    async khonkyara(){
      // ค้นหาตัวละครจากชื่อ
      let r = await axios.get("/khon/kyara/namae/"+this.kyara.namae);
      console.log(r.data);
      if(r.data){ // หากค้นเจอตัวละคร
        this.kyara = r.data.kyara;
        this.walidet.seiyuu_n = r.data.seiyuu_n; // ชื่อนักพากย์
        this.walidet.doi_id = this.kyara.id;
        this.sathana.push(["ค้นตัวละครได้: " + r.data.kyara.id, "/ami/kae/kyara/"+r.data.kyara.id]);
        this.sathana.push(["นักพากย์: " + r.data.seiyuu_n , "/ami/kae/seiyuu/"+r.data.seiyuu_n]);
      }
      else { // หากค้นไม่เจอ
        this.sathana.push(["ไม่พบตัวละคร"]);
      }
    },
    async banthuek(){ // บันทึกข้อมูล
      this.sathana = [];
      let url,khomun,r,link;

      // ตรวจดูให้แน่ว่ามีส่วนไหนขาดไม่ได้เติม
      if(!this.walidet.lek){
        this.sathana.push(["ไม่ได้ใส่ lek"]);
        return 0;
      }
      if(!this.walidet.wali_nh){
        this.sathana.push(["ไม่ได้ใส่ข้อความภาษาญี่ปุ่น"]);
        return 0;
      }
      if(!this.walidet.wali_th){
        this.sathana.push(["ไม่ได้ใส่ข้อความภาษาไทย"]);
        return 0;
      }
      if(!this.walidet.wali_ro){
        this.sathana.push(["ไม่ได้ใส่ข้อความโรมาจิ"]);
        return 0;
      }
      if(!(this.walidet.anime_n||this.walidet.game_n||this.walidet.doi_n)){
        this.sathana.push(["ไม่ได้ใส่ที่มาเลย"]);
        return 0;
      }
      if(this.muat!="khondang"&&!this.romaji){
        this.sathana.push(["ไม่ได้ใส่โรมาจิชื่ออนิเมะ/เกม"]);
        return 0;
      }
      // เริ่มการบันทึกข้อมูล
      if(this.walidet.anime_n){ // บันทึกข้อมูลอนิเมะ
        url = "/api/phoem/anime/"
        khomun = {khomun: {
          namae: this.walidet.anime_n,
          romaji: this.romaji
        }}
        r = await axios.post(url,khomun);
        link = "/ami/kae/anime/"+encodeURIComponent(this.walidet.anime_n);
        if(typeof r.data=="string") this.sathana.push([r.data,link]);
        else{
          this.sathana.push(["เพิ่มอนิเมะ " + this.walidet.anime_n,link]);
        }
      }
      else if(this.walidet.game_n){ // บันทึกข้อมูลเกม
        url = "/api/phoem/game/"
        khomun = {khomun: {
          namae: this.walidet.game_n,
          romaji: this.romaji
        }}
        r = await axios.post(url,khomun);
        link = "/ami/kae/game/"+encodeURIComponent(this.walidet.game_n);
        if(typeof r.data=="string") this.sathana.push([r.data,link]);
        else{
          this.sathana.push(["เพิ่มเกม " + this.walidet.game_n,link]);
        }
      }
      else if(this.walidet.doi_n){ // บันทึกข้อมูลคนดัง
        url = "/api/phoem/khondang";
        this.khondang.namae = this.walidet.doi_n;
        khomun = {khomun: this.khondang};
        r = await axios.post(url,khomun);
        link = "/ami/kae/khondang/" + this.walidet.doi_n;
        if(typeof r.data=="string") this.sathana.push([r.data]);
        else{
          this.sathana.push(["เพิ่มคนดัง " + this.walidet.doi_n,link]);
        }
      }
      if(this.kyara.namae){ // บันทึกข้อมูลตัวละคร
        if(this.walidet.doi_id) this.kyara.id = this.walidet.doi_id;
        url = "/api/phoem/kyara";
        khomun = {khomun: this.kyara};
        r = await axios.post(url,khomun);
        link = "/ami/kae/kyara/" + this.kyara.id;
        if(typeof r.data=="string") this.sathana.push([r.data,link]);
        else{
          this.kyara = r.data;
          this.walidet.doi_id = this.kyara.id;
          link = "/ami/kae/kyara/" + this.kyara.id;
          this.sathana.push(["เพิ่มตัวละคร " + this.kyara.id + " " + this.kyara.namae,link]);
        }
      }
      if(this.walidet.seiyuu_n){ // บันทึกข้อมูลนักพากย์
        url = "/api/phoem/seiyuu";
        khomun = {khomun: {namae: this.walidet.seiyuu_n}};
        r = await axios.post(url,khomun);
        link = "/ami/kae/seiyuu/" + this.walidet.seiyuu_n;
        if(typeof r.data=="string") this.sathana.push([r.data,link]);
        else{
          this.sathana.push(["เพิ่มนักพากย์ " + this.walidet.seiyuu_n,link]);
        }
      }
      
      // บันทึกข้อมูลวลีเด็ด
      url = "/api/phoem/walidet" + this.muat;
      khomun = {khomun: this.walidet};
      r = await axios.post(url,khomun);
      console.log(r.data);
      if(typeof r.data=="string") this.sathana.push([r.data]);
      else {
        this.sathana.push([
          "เพิ่มวลีเด็ด " + this.muat + " " + this.walidet.lek + " แล้ว",
          "/ami/kae/walidet" + this.muat + "/" + this.walidet.lek
        ]);
      }
    },
    lopmot(){ // ลบข้อมูลในตารางให้เกลี้ยง
      this.khokhwam = "";
      this.romaji = "";
      this.walidet = {
        lek: "", wali_nh: "", wali_th: "", wali_ro: "", anime_n: "",
        ton: "", game_n: "", doi_id: "", seiyuu_n: "", doi_n: "",
      }
      this.khondang = {
        namae: "", kana: "", th: "", dare: "", sngp: ""
      }
      this.kyara = {
        id: "", namae: "", th: "", hoka: ""
      };
    }
  },
  created(){
    this.lopmot();
  }
}
</script>