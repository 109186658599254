<template>
  <div>
    <v-data-table
      :headers="cola"
      :items="khomunnaitarang"
      :items-per-page.sync="nala"
      :page="nathi"
      :sort-by="khomun.praki"
      hide-default-footer
      dark
    >
      <template v-slot:top>
        <v-card-title>
          <table>
            <tr dense>
              <td class="subtitle-1">แสดงหน้าละ
                <v-text-field v-model.number="nala" min="5" :max="khomunnaitarang.length" type="number" style="width: 120px" filled outlined dense></v-text-field>
              </td>
              <td><v-btn class="primary" @click="sadaengmot()">แสดงทั้งหมด</v-btn></td>
              <td class="subtitle-1">หน้าที่
                <v-text-field v-model.number="nathi" min="1" :max="parseInt(khomunnaitarang.length/nala+1)" type="number" style="width: 80px" filled outlined dense></v-text-field>
              </td>
            </tr>
          </table>
        </v-card-title>
        <v-toolbar flat color="#337">
          <v-toolbar-title>ตารางข้อมูล {{khomun.chuetarang}}</v-toolbar-title>
          <v-divider
            inset
            vertical
          ></v-divider>
          <v-spacer></v-spacer>
          <v-dialog v-model="sadaengkropkae" max-width="90%">
            <template v-slot:activator="{ on }">
              <v-btn color="primary" v-on="on">เพิ่มข้อมูลใหม่</v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="headline">{{khokhwamkrop}}</span>
              </v-card-title>

              <v-card-text>
                <v-container class="d-flex flex-wrap">
                  <div v-for="h in cola" v-if="!['kaelop','cu'].includes(h.value)" style="width: 50%; border: 1px solid #123">
                    <v-text-field v-model="khomunmai[h.value]" :label="h.value" style="margin: 5px"></v-text-field>
                  </div>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="pitkrop">ยกเลิก</v-btn>
                <v-btn color="blue darken-1" text @click="banthuek">บันทึก</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.kaelop="{ item }">
        <a :href="'/ami/kae/'+khomun.chuetarang+'/'+encodeURIComponent(item[khomun.praki])" target="_blank">ไปหน้าแก้</a>
        <v-btn color="#3a3" @click="kae(item)">แก้</v-btn>
        <v-btn color="#a33" @click="lop(item)">ลบ</v-btn>
      </template>
      <template v-slot:item.cu="{ item }" light>
        <div class="overline font-weight-bold" style="color: #cce">{{item.created_at.split(".")[0].replace("T"," ")}}</div>
        <hr>
        <div class="overline font-weight-bold" style="color: #ecf">{{item.updated_at.split(".")[0].replace("T"," ")}}</div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import axios from 'axios';
import axioscsrf from '../buki/axioscsrf';
axioscsrf(axios);

export default {
  props: {
    khomun: Object,
  },
  data(){
    return {
      khomunnaitarang: [], // ข้อมูลในตารางข้อมูลที่ต้องการค้น
      nala: 100, // จำนวนหน้าที่แสดง
      nathi: 1, // อยู่นห้าที่
      cola: [], // ชื่อคอลัมน์
      ind_kae: -1, // ดัชนีในตารางของข้อมูลตัวที่กำลังแก้อยู่
      khomunmai: {}, // ข้อมูลที่จะเพิ่มหรือแก้ใหม่
      sadaengkropkae: false, // ซ่อนแสดงกรอบแก้ข้อมูล
    }
  },
  computed: {
    khokhwamkrop() { // ข้อความภายในกรอบเพิ่มหรือแก้ข้อมูล
      if(this.ind_kae == -1) return "เพิ่มข้อมูล";
      else return "แก้ข้อมูล";
    },
    khapraki() { // ค่าไพรมารีคีย์ของข้อมูลที่แก้อยู่
      let khomundoem = this.khomunnaitarang[this.ind_kae];
      return khomundoem[this.khomun.praki];
    }
  },
  methods: {
    async duengkhomun(){ // ดึงข้อมูลตารางมา
      let r = await axios.get('/api/lai/'+this.khomun.chuetarang);
      this.khomunnaitarang = r.data; // ได้ข้อมูลมาใส่ตาราง
      this.cola = [] // ชื่อคอลัมน์
      Object.keys(this.khomunnaitarang[0]).map((x)=>{ // ไล่เพิ่มทีละคอลัมน์ ยกเว้นส่วนวันที่สร้างและแก้ไข
        if(!['created_at','updated_at'].includes(x)){
          this.cola.push({
            text: x,
            align: 'start',
            sortable: true,
            value: x
          });
        }
      });
      // แถบสำหรับแก้ไขและลบ
      this.cola.push({
        text: "แก้/ลบ",
        align: 'start',
        sortable: false,
        value: 'kaelop'
      });
      // ส่วนแสดงวันที่สร้างและแก้ไข
      this.cola.push({
        text: "สร้างและแก้ไขเมื่อ",
        align: 'start',
        sortable: false,
        value: 'cu'
      });
    },
    async kae(item){ // แก้ข้อมูล
        this.ind_kae = this.khomunnaitarang.indexOf(item);
        this.khomunmai = Object.assign({}, item);
        this.sadaengkropkae = true;
    },
    async pitkrop(item){ // ปิดกรอบแก้หรือเพิ่มข้อมูล
      this.sadaengkropkae = false;
      this.khomunmai = {};
      this.ind_kae = -1;
    },
    async banthuek(item){ // บันทึกข้อมูลที่แก้หรือเพิ่มใหม่
      if (this.ind_kae > -1) { // กรณีที่แก้ข้อมูลเดิม
        let url = "/api/kae/" + this.khomun.chuetarang+"/" + encodeURIComponent(this.khapraki);
        let r = await axios.patch(url,{khomun: this.khomunmai});
        console.log(r);
        Object.assign(this.khomunnaitarang[this.ind_kae],r.data); // แก้ข้อมูลลงในตาราง
      }
      else { // กรณีที่เพิ่มข้อมูลใหม่
        let url = "/api/phoem/" + this.khomun.chuetarang;
        let r = await axios.post(url,{khomun: this.khomunmai});
        console.log(r);
        if(typeof r.data=="string") console.log(r.data)
        else this.khomunnaitarang.push(r.data); // เพิ่มข้อมูลเข้าไปในตาราง
      }
      await this.pitkrop()
    },
    async lop(item){ // เมื่อกดปุ่มลบข้อมูล
      if(confirm('จะลบแน่หรือ?')){ // ยืนยันให้แน่ใจว่าไม่กดผิด
        this.ind_kae = this.khomunnaitarang.indexOf(item); // ดัชนีในตารางของข้อมูลที่กำลังจะลบ
        let url = "/api/lop/" + this.khomun.chuetarang+"/" + encodeURIComponent(this.khapraki);
        console.log(url)
        let r = await axios.delete(url); // ทำการลบทิ้ง
        this.khomunnaitarang.splice(this.ind_kae, 1); // ลบข้อมูลออกจากตาราง
      }
    },
    sadaengmot(){ // เมื่อกดปุ่มให้แสดงข้อมูลทั้งหมด
      this.nala = this.khomunnaitarang.length;
      this.nathi = 1;
    }
  },
  created(){
    this.duengkhomun(); // ดึงข้อมูลตอนเริ่มต้น
  }
}
</script>