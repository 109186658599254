<template>
  <v-container>          
    <v-data-iterator
      :items="arr_khondang"
      :items-per-page="arr_khondang.length"
      hide-default-footer
    >
      <template v-slot:default="p">
        <div><span class="title font-weight-bold">รายชื่อคนดังที่มีข้อมูลวลีเด็ด</span> ทั้งหมด {{arr_khondang.length}} คน</div>
        <v-row>
          <v-col v-for="c in arr_withiriang">
            <input type="radio" :key="c[0]" :value="c[0]" v-model="withiriang" > {{c[1]}}
          </v-col>
          <v-col></v-checkbox><input type="checkbox" v-model="riangklap">
          {{{1: 'เรียงจากหลัง',2: 'เรียงจากหลัง',3: 'เรียงจากอายุมาก',4: 'เรียงจากน้อย'}[withiriang]}}
          </v-col>
        </v-row>
        
        <div v-for="k in p.items" align="left" style="margin: 5px">
          <div class="d-flex justify-start">
            <span v-if="withiriang==3">[{{dueanpi(k.sngp,true,true)}}]&nbsp;</span>
            <a :href="'../khondang/'+k.namae" data-method="get">{{k.th}} ~ {{k.namae}}</a>
            <span style="color: #752">&nbsp;({{k.n_walidet}})</span>
            
          </div>
        </div>
      </template>
    </v-data-iterator>
  </v-container>
</template>

<script>
// หน้าแสดงรายชื่อคนดังทั้งหมด
import dueanpi from '../buki/dueanpi.js';
import kanahenkan from '../buki/kanahenkan.js';
import eaeoai from '../buki/eaeoai.js';

export default {
  props: {
    khomun: Object,
  },
  data() {
    return {
      dueanpi, // ฟังก์ชันแปลงเป็นวันเดือนปีแบบไทย
      arr_withiriang: [ // วิธีการเรียงทั้งหมด
        [1,'เรียงตามชื่อภาษาญี่ปุ่น'],
        [2,'เรียงตามชื่อภาษาไทย'],
        [3,'เรียงตามวันเกิด'],
        [4,'เรียงตามจำนวนวลีเด็ด']
      ],
      withiriang: this.khomun.riang, // วิธีการเรียงที่เลือกอยู่
      riangklap: false // เรียงกลับด้านหรือไม่
    }
  },
  computed: {
    arr_khondang(){
      let arr = this.khomun.arr_khondang;
      if(this.withiriang==3){ // คัดเอาเฉพาะที่มีวันเกิด
        arr = arr.filter((s)=>{
          return s.sngp;
        });
      }
      return arr;
    }
  },
  methods: {
    riangmai(){ // ทำการจัดเรียงใหม่
      let riang // กำหนดฟังก์ชันที่จะใช้เรียง
      if(this.withiriang==1){ // เรียงตามชื่อญี่ปุ่น
        riang = (a,b)=>{return {false: -1, true: 1}[kanahenkan(a.kana) > kanahenkan(b.kana)];};
      }
      else if(this.withiriang==2){ // เรียงตามชื่อภาษาไทย
        riang = (a,b)=>{return {false: -1, true: 1}[eaeoai(a.th) > eaeoai(b.th)];};
      }
      else if(this.withiriang==3){// เรียงตามวันเดือนปีเกิด
        riang = (a,b)=>{return {false: -1, true: 1}[a.sngp < b.sngp];};
      }
      else{ // เรียงตามจำนวนวลีเด็ดที่มี
        riang = (a,b)=>{return {false: -1, true: 1}[a.n_walidet < b.n_walidet];};
      }
      this.arr_khondang.sort(riang); // เริ่มการเรียง
      if(this.riangklap) this.arr_khondang.reverse() // เรียงกลับ
      // ทำการแก้ url ไปตามโหมดการเรียง
      let lek = parseInt(this.withiriang);
      if(this.riangklap) lek += 4
      window.history.pushState(null, null, "/khondangthangmot/"+lek);
    }
  },
  created(){
    if(this.withiriang>4) {
      this.withiriang -= 4;
      this.riangklap = true;
    }
    this.riangmai()
  },
  watch: { // ถ้ามีการเปลี่ยนวิธีเรียงให้ทำการเรียงใหม่
    withiriang(){
      this.riangmai()
    },
    riangklap(){
      this.riangmai()
    }
  }
}
</script>