<template>
  <v-container class="d-flex flex-wrap justify-center" style="max-width: 900px">
    <div v-for="p in phapsumriang">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <a :href="'/walidet/'+p.lek" data-method="get">
            <v-img :src="'https://hinaboshi.com/rup/rupprakopwalidet/'+p.lek+'.jpg'" width="150" v-on="on"></v-img>
          </a>
        </template>
        <span >{{p.wali_th}}</span>
      </v-tooltip>
    </div>
  </v-container>
</template>

<script>
// ส่วนเรียงภาพวลีเด็ดสุ่มที่ปรากฏในหน้าต่างๆ
export default {
  props: {
    walidet: Array,
  },
  data(){
    return {
    }
  },
  computed: {
    phapsumriang(){ // สุ่มเรียงแล้วคัด
      return this.walidet.sort(()=>{return {false: -1, true: 1}[(Math.random()>0.5)]}).slice(0,10)
    }
  }
}
</script>