var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "title" },
        [
          _vm._v("\n    แก้ " + _vm._s(_vm.khomun.chuetarang) + "\n    "),
          ["anime", "khondang", "seiyuu"].includes(_vm.khomun.chuetarang)
            ? _c("div", [
                _c(
                  "a",
                  {
                    attrs: {
                      href:
                        "/" +
                        _vm.khomun.chuetarang +
                        "/" +
                        encodeURIComponent(_vm.khomun.khapraki),
                      target: "_blank"
                    }
                  },
                  [_vm._v(_vm._s(_vm.khomun.khapraki))]
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.khomun.chuetarang == "game"
            ? _c("div", [
                _c(
                  "a",
                  {
                    attrs: {
                      href:
                        "/zenigame/" + encodeURIComponent(_vm.khomun.khapraki),
                      target: "_blank"
                    }
                  },
                  [_vm._v(_vm._s(_vm.khomun.khapraki))]
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          ["walidetanime", "walidetgame", "walidetkhondang"].includes(
            _vm.khomun.chuetarang
          )
            ? _c("div", [
                _c(
                  "a",
                  {
                    attrs: {
                      href: "/walidet/" + _vm.khomun.khapraki,
                      target: "_blank"
                    }
                  },
                  [_vm._v(_vm._s(_vm.khomun.khapraki))]
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.khomun.chuetarang == "kyara"
            ? _c("div", [
                _c(
                  "a",
                  {
                    attrs: {
                      href: "/tualakhon/" + _vm.khomun.khapraki,
                      target: "_blank"
                    }
                  },
                  [
                    _vm._v(
                      _vm._s(_vm.khomun.khomuntuani.th) +
                        " ~ " +
                        _vm._s(_vm.khomun.khomuntuani.namae)
                    )
                  ]
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm._l(_vm.sathana, function(s) {
            return _c("div", { staticStyle: { color: "#161" } }, [
              _vm._v("\n      " + _vm._s(s) + "\n    ")
            ])
          }),
          _c("br")
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "v-container",
        { staticClass: "d-flex flex-wrap" },
        _vm._l(Object.keys(_vm.khomun.khomuntuani), function(k) {
          return _c(
            "div",
            { staticStyle: { width: "50%", border: "1px solid #123" } },
            [
              _c("v-text-field", {
                staticClass: "title",
                staticStyle: { margin: "5px" },
                attrs: { filled: "", label: k, color: "#193" },
                model: {
                  value: _vm.khomun.khomuntuani[k],
                  callback: function($$v) {
                    _vm.$set(_vm.khomun.khomuntuani, k, $$v)
                  },
                  expression: "khomun.khomuntuani[k]"
                }
              })
            ],
            1
          )
        }),
        0
      ),
      _vm._v(" "),
      _c(
        "v-container",
        { staticClass: "d-flex" },
        [
          _c(
            "v-btn",
            {
              staticClass: "title",
              attrs: { color: "blue darken-1" },
              on: {
                click: function($event) {
                  return _vm.banthuek()
                }
              }
            },
            [_vm._v("บันทึก")]
          ),
          _vm._v(" "),
          _c(
            "v-btn",
            {
              staticClass: "title",
              attrs: { color: "warning" },
              on: {
                click: function($event) {
                  return _vm.lop()
                }
              }
            },
            [_vm._v("ลบทิ้ง")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }