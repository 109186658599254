<template>
  <v-container>          
    <v-data-iterator
      :items="arr_anime"
      :items-per-page.sync="nala"
      :page="nathi"
      hide-default-footer
    >
      <template v-slot:default="p">
        <div class="title">
          <span class="font-weight-bold">รายชื่ออนิเมะที่มีข้อมูลวลีเด็ด</span> (แสดงเรื่องที่ {{i_roem}} ถึง {{i_chop}} จากทั้งหมด {{arr_anime.length}})
        </div>
        <v-card-title>
          <table>
            <tr dense>
              <td class="subtitle-1">แสดงหน้าละ
                <v-text-field v-model.number="nala" min="5" :max="arr_anime.length" type="number" style="width: 120px" filled outlined dense></v-text-field>
              </td>
              <td class="subtitle-1">หน้าที่
                <v-text-field v-model.number="nathi" min="1" :max="parseInt(arr_anime.length/nala+1)" type="number" style="width: 80px" filled outlined dense></v-text-field>
              </td>
            </tr>
          </table>
        </v-card-title>
        
        <v-row dense>
          <v-col v-for="c in arr_withiriang">
            <input type="radio" :key="c[0]" :value="c[0]" v-model="withiriang" > {{c[1]}}
          </v-col>
          <v-col></v-checkbox><input type="checkbox" v-model="riangklap">
          {{{1: 'เรียงจากหลัง',2: 'เรียงจากหลัง',3: 'เรียงจากเก่า',4: 'เรียงจากน้อย'}[withiriang]}}
          </v-col>
        </v-row>
        
        <div v-for="a in p.items" style="margin: 5px" align="left">
          <v-container class="d-flex justify-start">
            <span v-if="withiriang==3">[{{dueanpi(a.ngp,true)}}]&nbsp;</span>
            <a :href="'../anime/'+encodeURIComponent(a.namae)" data-method="get"> {{a.namae}}
            <span v-if="a.namae!=a.romaji"> / {{a.romaji}}</span></a> <span style="color: #752">&nbsp;({{a.n_walidet}})</span>
          </v-container>
          <v-divider></v-divider>
        </div>
      </template>
    </v-data-iterator>
  </v-container>
</template>

<script>
// หน้าแสดงรายชื่ออนิเมะทั้งหมด
import dueanpi from '../buki/dueanpi.js';
import kanahenkan from '../buki/kanahenkan.js';

export default {
  props: {
    khomun: Object,
  },
  data() {
    return {
      dueanpi, // ฟังก์ชันแปลงเป็นวันเดือนปีแบบไทย
      nala: 50, // แสดงหน้าละ
      nathi: 1, // หน้าที่แสดงอยู่
      arr_withiriang: [ // วิธีการเรียงทั้งหมด
        [1,'เรียงตามชื่อภาษาญี่ปุ่น'],
        [2,'เรียงตามชื่อโรมาจิ'],
        [3,'เรียงตามวันที่อนิเมะฉาย'],
        [4,'เรียงตามจำนวนวลีเด็ด']
      ],
      withiriang: this.khomun.riang, // วิธีการเรียงที่เลือกอยู่
      arr_anime: this.khomun.anime, // ข้อมูลอนิเมะทั้งหมด
      riangklap: false // เรียงกลับด้านหรือไม่
    }
  },
  computed: {
    i_roem(){ // ดัชนีของตัวแรกที่แสดง
      return Math.max(Math.min(1+(this.nathi-1)*this.nala, this.arr_anime.length), 1);
    },
    i_chop(){ // ดัชนีของตัวสุดท้ายที่แสดง
      return Math.max(Math.min(this.nathi*this.nala, this.arr_anime.length), 1);
    }
  },
  methods: {
    riangmai(){ // ทำการจัดเรียงใหม่
      let riang // กำหนดฟังก์ชันที่จะใช้เรียง
      if(this.withiriang==1){ // เรียงตามชื่อญี่ปุ่น
        riang = (a,b)=>{return {false: -1, true: 1}[kanahenkan(a.kana) > kanahenkan(b.kana)];};
      }
      else if(this.withiriang==2){ // เรียงตามชื่อโรมาจิ
        riang = (a,b)=>{return {false: -1, true: 1}[a.romaji.toLowerCase() > b.romaji.toLowerCase()];};
      }
      else if(this.withiriang==3){ // เรียงตามวันที่ฉาย
        riang = (a,b)=>{return {false: -1, true: 1}[a.ngp < b.ngp];};
      }
      else{ // เรียงตามจำนวนวลีเด็ดที่มี
        riang = (a,b)=>{return {false: -1, true: 1}[a.n_walidet < b.n_walidet];};
      }
      this.arr_anime.sort(riang); // เริ่มการเรียง
      if(this.riangklap) this.arr_anime.reverse(); // เรียงกลับ
      // ทำการแก้ url ไปตามโหมดการเรียง
      let lek = parseInt(this.withiriang);
      if(this.riangklap) lek += 4;
      window.history.pushState(null, null, "/animethangmot/"+lek);
    },
  },
  created(){ // เริ่มต้นมาให้ดูว่าจะเรียงแบบไหน
    if(this.withiriang>4) {
      this.withiriang -= 4;
      this.riangklap = true;
    }
    this.riangmai()
  },
  watch: { // ถ้ามีการเปลี่ยนวิธีเรียงให้ทำการเรียงใหม่
    withiriang(){
      this.riangmai()
    },
    riangklap(){
      this.riangmai()
    }
  }
}
</script>