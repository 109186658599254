var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    {
      staticClass: "d-flex flex-wrap justify-center",
      staticStyle: { "max-width": "900px" }
    },
    _vm._l(_vm.phapsumriang, function(p) {
      return _c(
        "div",
        [
          _c(
            "v-tooltip",
            {
              attrs: { bottom: "" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function(ref) {
                      var on = ref.on
                      return [
                        _c(
                          "a",
                          {
                            attrs: {
                              href: "/walidet/" + p.lek,
                              "data-method": "get"
                            }
                          },
                          [
                            _c(
                              "v-img",
                              _vm._g(
                                {
                                  attrs: {
                                    src:
                                      "https://hinaboshi.com/rup/rupprakopwalidet/" +
                                      p.lek +
                                      ".jpg",
                                    width: "150"
                                  }
                                },
                                on
                              )
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ],
                null,
                true
              )
            },
            [_vm._v(" "), _c("span", [_vm._v(_vm._s(p.wali_th))])]
          )
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }