<template>
  <v-app style="background: #fee9da">
    <nabon></nabon>
    <v-content app>
      <v-container>
        <v-card elevation="24" color="#cdfffe" align="center" style="border: 3px #4a40ff solid">
          <naweb :khomun="khomun"></naweb>
        </v-card>
      </v-container>
    </v-content>
  </v-app>
</template>

<script>
// หน้าเว็บหลัก
import nalak from 'ima/nalak.vue';
import walidet from 'ima/walidet.vue';
import walidetthangmot from 'ima/walidetthangmot.vue';
import anime from 'ima/anime.vue';
import animethangmot from 'ima/animethangmot.vue';
import zenigame from 'ima/zenigame.vue';
import gamethangmot from 'ima/gamethangmot.vue';
import khondang from 'ima/khondang.vue';
import khondangthangmot from 'ima/khondangthangmot.vue';
import seiyuu from 'ima/seiyuu.vue';
import seiyuuthangmot from 'ima/seiyuuthangmot.vue';
import tualakhon from 'ima/tualakhon.vue'
import kiaokap from 'ima/kiaokap.vue'

// หน้าจัดการข้อมูล
import ami from 'ami/ami.vue';
import laikhomun from 'ami/laikhomun.vue';
import kae from 'ami/kae.vue';
import saiwalidet from 'ami/saiwalidet.vue';

import bonima from 'ima/bon.vue'; // ส่วนบนหน้าเว็บหลัก
import bonami from 'ami/bon.vue'; // ส่วนบนหน้าจัดการข้อมูล

// ดึงข้อมูลที่เตรียมไว้จาก ruby
let khomun = JSON.parse(document.getElementById("khomuntriamwai").textContent);
console.log(khomun);

let naweb = {
  nalak,
  walidet,
  walidetthangmot,
  anime,
  animethangmot,
  zenigame,
  gamethangmot,
  khondang,
  khondangthangmot,
  seiyuu,
  seiyuuthangmot,
  tualakhon,
  kiaokap,
  ami,
  laikhomun,
  kae,
  saiwalidet
}[khomun["chuena"]]; // เลือกส่วนหลักตามชื่อหน้า
let nabon = {bonima, bonami}[khomun["chuenabon"]]; // เลือกส่วนหัว

export default {
  components: {
    naweb, // ส่วนหลักของตัวเว็บ
    nabon, // ส่วนด้านบน
  },
  data(){
    return {
      khomun
    }
  }
};
</script>

<style scoped>

</style>
