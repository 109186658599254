var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c("v-data-iterator", {
        attrs: {
          items: _vm.arr_game,
          "items-per-page": _vm.arr_game.length,
          "hide-default-footer": ""
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(p) {
              return [
                _c("div", [
                  _c("span", { staticClass: "title font-weight-bold" }, [
                    _vm._v("รายชื่อเกมที่มีข้อมูลวลีเด็ด")
                  ]),
                  _vm._v(" ทั้งหมด " + _vm._s(_vm.arr_game.length) + " เกม")
                ]),
                _vm._v(" "),
                _c(
                  "v-row",
                  [
                    _vm._l(_vm.arr_withiriang, function(c) {
                      return _c("v-col", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.withiriang,
                              expression: "withiriang"
                            }
                          ],
                          key: c[0],
                          attrs: { type: "radio" },
                          domProps: {
                            value: c[0],
                            checked: _vm._q(_vm.withiriang, c[0])
                          },
                          on: {
                            change: function($event) {
                              _vm.withiriang = c[0]
                            }
                          }
                        }),
                        _vm._v(" " + _vm._s(c[1]) + "\n        ")
                      ])
                    }),
                    _vm._v(" "),
                    _c("v-col", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.riangklap,
                            expression: "riangklap"
                          }
                        ],
                        attrs: { type: "checkbox" },
                        domProps: {
                          checked: Array.isArray(_vm.riangklap)
                            ? _vm._i(_vm.riangklap, null) > -1
                            : _vm.riangklap
                        },
                        on: {
                          change: function($event) {
                            var $$a = _vm.riangklap,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 && (_vm.riangklap = $$a.concat([$$v]))
                              } else {
                                $$i > -1 &&
                                  (_vm.riangklap = $$a
                                    .slice(0, $$i)
                                    .concat($$a.slice($$i + 1)))
                              }
                            } else {
                              _vm.riangklap = $$c
                            }
                          }
                        }
                      }),
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            {
                              1: "เรียงจากหลัง",
                              2: "เรียงจากหลัง",
                              3: "เรียงจากเก่า",
                              4: "เรียงจากน้อย"
                            }[_vm.withiriang]
                          ) +
                          "\n        "
                      )
                    ])
                  ],
                  2
                ),
                _vm._v(" "),
                _vm._l(p.items, function(g) {
                  return _c(
                    "div",
                    {
                      staticStyle: { margin: "5px" },
                      attrs: { align: "left" }
                    },
                    [
                      _c(
                        "v-container",
                        { staticClass: "d-flex justify-start" },
                        [
                          _vm.withiriang == 3
                            ? _c("span", [
                                _vm._v(
                                  "[" + _vm._s(_vm.dueanpi(g.ngp, true)) + "] "
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              attrs: {
                                href:
                                  "../zenigame/" + encodeURIComponent(g.namae),
                                "data-method": "get"
                              }
                            },
                            [
                              _vm._v(" " + _vm._s(g.namae) + "\n          "),
                              g.namae != g.romaji
                                ? _c("span", [_vm._v(" / " + _vm._s(g.romaji))])
                                : _vm._e()
                            ]
                          ),
                          _vm._v(" "),
                          _c("span", { staticStyle: { color: "#752" } }, [
                            _vm._v("(" + _vm._s(g.n_walidet) + ")")
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c("v-divider")
                    ],
                    1
                  )
                })
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }