var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-carousel",
    {
      attrs: {
        cycle: "",
        height: "130",
        vertical: "",
        "hide-delimiter-background": "",
        "show-arrows": false
      }
    },
    _vm._l(_vm.phap, function(pp, i) {
      return _c(
        "v-carousel-item",
        { key: i },
        [
          _c(
            "v-row",
            [
              _c("v-col", [
                _c("table", [
                  _c(
                    "tr",
                    _vm._l(pp, function(p) {
                      return _c(
                        "td",
                        { staticStyle: { "vertical-align": "top" } },
                        [
                          _c(
                            "v-tooltip",
                            {
                              attrs: { bottom: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      return [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              href: "/walidet/" + p.lek,
                                              "data-method": "get"
                                            }
                                          },
                                          [
                                            _c(
                                              "v-img",
                                              _vm._g(
                                                {
                                                  attrs: {
                                                    src:
                                                      "https://hinaboshi.com/rup/rupprakopwalidet/" +
                                                      p.lek +
                                                      ".jpg",
                                                    width: "150"
                                                  }
                                                },
                                                on
                                              )
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            },
                            [
                              _vm._v(" "),
                              _c("span", [_vm._v(_vm._s(p.wali_th))])
                            ]
                          )
                        ],
                        1
                      )
                    }),
                    0
                  )
                ])
              ])
            ],
            1
          )
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }