<template>
  <v-container>
    <div class="headline font-weight-bold">
      {{khomun.khondang.th}}<br>{{khomun.khondang.namae}}
    </div>
    <v-card shaped style="border: solid #999 3px">
      <v-container>
        <table class="title">
          <tr><td>ชื่อญี่ปุ่น :</td><td>{{khomun.khondang.namae}}</td></tr>
          <tr><td>คานะ :</td><td>{{khomun.khondang.kana}}</td></tr>
          <tr><td>ชื่อไทย :</td><td>{{khomun.khondang.th}}</td></tr>
          <tr v-if="khomun.khondang.sngp"><td>วันเกิด :</td>{{dueanpi(khomun.khondang.sngp,false,true)}}<td></td></tr>
          <tr><td>เป็น :</td>{{khomun.khondang.dare}}<td></td></tr>
          <tr><td></td><td><a :href="'https://ja.wikipedia.org/wiki/'+khomun.khondang.namae.replace(' ','')" target="_blank">wikipedia</a></td></tr>
          <tr><td>มีวลีเด็ดทั้งหมด :</td><td>{{khomun.walidet.length}}</td></tr>
        </table>
      </v-container>
    </v-card>
    <riangphapwalidet :walidet="khomun.walidet"></riangphapwalidet>
    <br>
    <kropruamwalidet :walidet="khomun.walidet"></kropruamwalidet>
    <v-container class="fb-like" data-share="true" data-width="450" data-show-faces="true"></v-container>
  </v-container>
</template>

<script>
import kropruamwalidet from './kropruamwalidet.vue';
import riangphapwalidet from './riangphapwalidet.vue';
import dueanpi from '../buki/dueanpi.js';

export default {
  components: {
    kropruamwalidet,
    riangphapwalidet
  },
  props: {
    khomun: Object,
  },
  data(){
    return {
      dueanpi
    }
  }
}
</script>