<template>
  <v-container>
    <div class="headline font-weight-bold">
      {{khomun.kyara.th}}<br>{{khomun.kyara.namae}}
    </div>
    <div class="title font-weight-bold" align="left">ข้อมูลตัวละคร</div>
    <v-card shaped style="border: solid #999 3px">
      <v-container>
        <table class="title">
          <tr><td>ชื่อญี่ปุ่น :</td><td>{{khomun.kyara.namae}}</td></tr>
          <tr><td>ชื่อไทย :</td><td>{{khomun.kyara.th}}</td></tr>
          <tr v-if="khomun.kyara.hoka"><td>อื่นๆ :</td><td>{{khomun.kyara.hoka}}</td></tr>
          <tr><td>มีวลีเด็ดทั้งหมด :</td><td style="color: #722">{{khomun.kyara.arr_walidet.length}}</td></tr>
          <tr v-if="khomun.kyara.anime.length>0">
            <td>จากอนิเมะ :</td>
            <td>
              <div v-for="a in khomun.kyara.anime">
                <a :href="'/anime/'+encodeURIComponent(a.namae)" data-method="get">{{a.namae}} <span v-if="a.namae!=a.romaji">/ {{a.romaji}}</span></a>
              </div>
            </td>
          </tr>
          <tr v-if="khomun.kyara.game.length>0">
            <td>จากเกม :</td>
            <td>
              <div v-for="g in khomun.kyara.game">
                <a :href="'/zenigame/'+encodeURIComponent(g.namae)" data-method="get">{{g.namae}} <span v-if="g.namae!=g.romaji">/ {{g.romaji}}</span></a>
              </div>
            </td>
          </tr>
          <tr v-if="khomun.kyara.seiyuu.length>0">
            <td>นักพากย์ :</td>
            <td>
              <div v-for="s in khomun.kyara.seiyuu">
                <a :href="'/seiyuu/'+s.namae" data-method="get">{{s.th}} ~ {{s.namae}}</a>
              </div>
            </td>
          </tr>
        </table>
      </v-container>
    </v-card>
    <riangphapwalidet :walidet="khomun.kyara.arr_walidet"></riangphapwalidet>
    <br>
    <div class="title font-weight-bold">วลีเด็ดทั้งหมดจากตัวละครนี้</div>
    <div v-if="khomun.kyara.arr_walidet.length>0">
      <kropruamwalidet :walidet="khomun.kyara.arr_walidet"></kropruamwalidet>
    </div>
  </v-container>
</template>

<script>
// หน้าแสดงข้อมูลตัวละคร
import kropruamwalidet from './kropruamwalidet.vue';
import riangphapwalidet from './riangphapwalidet.vue';

export default {
  components: {
    kropruamwalidet, // กรอบแสดงวลีเด็ดที่มี
    riangphapwalidet // ส่วนสุมเรียงภาพวลีเด็ด
  },
  props: {
    khomun: Object,
  },
  data(){
    return {
      
    }
  }
}
</script>