var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-container", [
    _c("div", { staticClass: "display-1" }, [
      _vm._v("รวมคำแปลวลีเด็ดจากญี่ปุ่น")
    ]),
    _vm._v(" "),
    _c(
      "div",
      { attrs: { align: "left" } },
      [
        _vm._v(
          "\n    ขณะนี้มีวลีเด็ดรวมทั้งหมด " +
            _vm._s(_vm.khomun.n_walidet) +
            " วลีเด็ด"
        ),
        _c("br"),
        _vm._v(" "),
        _c("br"),
        _vm._v(
          "\n    " +
            _vm._s(_vm.khomun.n_walidetanime) +
            " วลีเด็ด จากอนิเมะ " +
            _vm._s(_vm.khomun.n_anime) +
            " เรื่อง"
        ),
        _c("br"),
        _vm._v(" "),
        _c("karuse", { attrs: { phap: _vm.khomun.walidetanime_sum_ao } }),
        _vm._v(" "),
        _c("br"),
        _vm._v(
          "\n    " +
            _vm._s(_vm.khomun.n_walidetgame) +
            " วลีเด็ด จากเกม " +
            _vm._s(_vm.khomun.n_game) +
            " เกม"
        ),
        _c("br"),
        _vm._v(" "),
        _c("karuse", { attrs: { phap: _vm.khomun.walidetgame_sum_ao } }),
        _vm._v(" "),
        _c("br"),
        _vm._v(
          "\n    " +
            _vm._s(_vm.khomun.n_walidetkhondang) +
            " วลีเด็ด จากคนดัง " +
            _vm._s(_vm.khomun.n_khondang) +
            " คน"
        ),
        _c("br"),
        _vm._v(" "),
        _c("karuse", { attrs: { phap: _vm.khomun.walidetkhondang_sum_ao } }),
        _vm._v(" "),
        _c("br"),
        _vm._v("\n    ข้อมูลนักพากย์ " + _vm._s(_vm.khomun.n_seiyuu) + " คน"),
        _c("br"),
        _vm._v(" "),
        _c("br"),
        _vm._v(
          "\n    เพิ่มฐานข้อมูลครั้งล่าสุด " +
            _vm._s(_vm.khomun.sai_khomun_lasut_muea)
        ),
        _c("br"),
        _vm._v(" "),
        _c("br"),
        _vm._v(
          "\n    ปรับปรุงเว็บครั้งล่าสุด " +
            _vm._s(_vm.khomun.prapprung_web_lasut_muea)
        ),
        _c("br"),
        _vm._v(" "),
        _c("br"),
        _vm._v("\n    เว็บนี้เป็นที่เก็บรวบรวมวลีเด็ดของเพจ "),
        _c(
          "a",
          {
            attrs: {
              href: "https://www.facebook.com/Animankyafu",
              target: "_blank"
            }
          },
          [_vm._v("https://www.facebook.com/Animankyafu")]
        ),
        _c("br"),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c("iframe", {
          staticStyle: {
            border: "none",
            overflow: "hidden",
            width: "500px",
            height: "240px"
          },
          attrs: {
            src:
              "//www.facebook.com/plugins/likebox.php?href=https%3A%2F%2Fwww.facebook.com%2Fanimankyafu&width=520&height=240&colorscheme=light&show_faces=true&header=true&stream=false&show_border=true",
            scrolling: "no",
            frameborder: "0",
            allowTransparency: "true"
          }
        }),
        _c("br"),
        _vm._v(" "),
        _c("div", {
          staticClass: "fb-like",
          attrs: {
            "data-share": "true",
            "data-width": "450",
            "data-show-faces": "true"
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }