<template>
  <v-carousel
    cycle
    height="130"
    vertical
    hide-delimiter-background
    :show-arrows="false"
  >
    <v-carousel-item
      v-for="(pp,i) in phap"
      :key="i"
    >
      <v-row>
        <v-col>
          <table>
            <tr>
              <td v-for="p in pp" style="vertical-align: top">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <a :href="'/walidet/'+p.lek" data-method="get">
                      <v-img :src="'https://hinaboshi.com/rup/rupprakopwalidet/'+p.lek+'.jpg'" width="150" v-on="on"></v-img>
                    </a>
                  </template>
                  <span >{{p.wali_th}}</span>
                </v-tooltip>
              </td>
            </tr>
          </table>
        </v-col>
      </v-row>
    </v-carousel-item>
  </v-carousel>
</template>

<script>
export default {
  props: {
    phap: Array, // เลขของภาพวลีเด็ดทั้งหมด
  },
  data(){
    return {
    }
  },
}
</script>

<style scoped>

</style>