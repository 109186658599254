<template>
  <v-container>
    <div class="headline font-weight-bold">
      {{khomun.seiyuu.th}}<br>{{khomun.seiyuu.namae}}
    </div>
    <div class="title font-weight-bold" align="left">ข้อมูลนักพากย์</div>
    <v-card shaped style="border: solid #999 3px">
      <v-container>
        <table class="title">
          <tr><td>ชื่อญี่ปุ่น :</td><td>{{khomun.seiyuu.namae}}</td></tr>
          <tr><td>คานะ :</td><td>{{khomun.seiyuu.kana}}</td></tr>
          <tr><td>ชื่อไทย :</td><td>{{khomun.seiyuu.th}}</td></tr>
          <tr v-if="khomun.seiyuu.sngp"><td>วันเกิด :</td>{{dueanpi(khomun.seiyuu.sngp,false,true)}}<td></td></tr>
          <tr v-if="khomun.seiyuu.chueuen">
            <td>ชื่ออื่น :</td>
            <td class="d-flex">
              <v-card v-for="c in khomun.seiyuu.chueuen" style="border: 1px solid #333">
                <table class="subtitle-1">
                  <tr><td>ชื่อญี่ปุ่น :</td><td>{{c.namae}}</td></tr>
                  <tr><td>คานะ :</td><td>{{c.kana}}</td></tr>
                  <tr><td>ชื่อไทย :</td><td>{{c.th}}</td></tr>
                  <tr><td>มีวลีเด็ด: </td><td style="color: #722">{{c.arr_walidetgame.length}}</td></tr>
                </table>
              </v-card>
            </td>
          </tr>
          <tr><td></td><td><a :href="'https://ja.wikipedia.org/wiki/'+khomun.seiyuu.namae.replace(' ','')" target="_blank">wikipedia</a></td></tr>
          <tr><td>มีวลีเด็ดทั้งหมด :</td><td style="color: #722">{{khomun.seiyuu.arr_walidetanime.length+khomun.seiyuu.arr_walidetgame.length}} (อนิเมะ {{khomun.seiyuu.arr_walidetanime.length}}, เกม {{khomun.seiyuu.arr_walidetgame.length}})</td></tr>
        </table>
      </v-container>
    </v-card>
    <riangphapwalidet :walidet="arr_ruamwalidet"></riangphapwalidet>
    <br>
    <div v-if="khomun.seiyuu.arr_kyaraanime.length>0">
      <div class="title font-weight-bold">ผลงานพากย์อนิเมะ</div>
      <v-card shaped style="border: solid #999 3px" align="left">
        <v-container>
          <div v-for="[k,n,aa] in khomun.seiyuu.arr_kyaraanime" align="left">
            <v-container>
              <a :href="'/tualakhon/'+k.id" data-method="get">{{k.th}} ~ {{k.namae}}<span v-if="k.hoka"> ~ {{k.hoka}}</span></a> ({{n}})<br>
              <span v-for="(a,i) in aa">
                <span v-if="i==0">จาก </span>
                <span v-else>, </span>
                <a :href="'/anime/'+encodeURIComponent(a.namae)" data-method="get">{{a.namae}}<span v-if="a.namae!=a.romaji"> / {{a.romaji}}</span></a>
              </span>
            </v-container>
          </div>
        </v-container>
      </v-card>
    </div>
    <br>
    <div v-if="khomun.seiyuu.arr_kyaragame.length>0">
      <div class="title font-weight-bold">ผลงานพากย์เกม</div>
      <v-card shaped style="border: solid #999 3px" align="left">
        <v-container>
          <div v-for="[k,n,gg] in khomun.seiyuu.arr_kyaragame" align="left">
            <v-container>
              <a :href="'/tualakhon/'+k.id" data-method="get">{{k.th}} ~ {{k.namae}}</a> ({{n}})<br>
              <span v-for="(g,i) in gg">
                <span v-if="i==0">จาก </span>
                <span v-else>, </span>
                <a :href="'/zenigame/'+encodeURIComponent(g.namae)" data-method="get">{{g.namae}} /<span v-if="g.namae!=g.romaji"> {{g.romaji}}</span></a>
              </span>
            </v-container>
          </div>
        </v-container>
      </v-card>
    </div>
    <br>
    <div class="title font-weight-bold">วลีเด็ดทั้งหมดจากผลงานพากย์</div>
    <div v-if="khomun.seiyuu.arr_walidetanime.length>0">
      <div class="title font-weight-bold" align="left">จากอนิเมะ</div>
      <kropruamwalidet :walidet="khomun.seiyuu.arr_walidetanime"></kropruamwalidet>
    </div>
    <br>
    <div v-if="khomun.seiyuu.arr_walidetgame.length>0">
      <div class="title font-weight-bold" align="left">จากเกม</div>
      <kropruamwalidet :walidet="khomun.seiyuu.arr_walidetgame"></kropruamwalidet>
    </div>
    <v-container class="fb-like" data-share="true" data-width="450" data-show-faces="true"></v-container>
  </v-container>
</template>

<script>
import kropruamwalidet from './kropruamwalidet.vue';
import riangphapwalidet from './riangphapwalidet.vue';
import dueanpi from '../buki/dueanpi.js';

export default {
  components: {
    kropruamwalidet, // กรอบแสดงวลีเด็ด
    riangphapwalidet // ส่วนแสดงภาพสุ่มวลีเด็ด
  },
  props: {
    khomun: Object,
  },
  data(){
    return {
      dueanpi
    }
  },
  computed: {
    arr_ruamwalidet(){ // รวมวลีเด็ดทั้งหมดจากทั้งอนิเมะและเกม
      return this.khomun.seiyuu.arr_walidetanime.concat(this.khomun.seiyuu.arr_walidetgame);
    }
  }
}
</script>