var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c("div", { staticClass: "headline font-weight-bold" }, [
        _vm._v("\n    " + _vm._s(_vm.khomun.khondang.th)),
        _c("br"),
        _vm._v(_vm._s(_vm.khomun.khondang.namae) + "\n  ")
      ]),
      _vm._v(" "),
      _c(
        "v-card",
        { staticStyle: { border: "solid #999 3px" }, attrs: { shaped: "" } },
        [
          _c("v-container", [
            _c("table", { staticClass: "title" }, [
              _c("tr", [
                _c("td", [_vm._v("ชื่อญี่ปุ่น :")]),
                _c("td", [_vm._v(_vm._s(_vm.khomun.khondang.namae))])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("คานะ :")]),
                _c("td", [_vm._v(_vm._s(_vm.khomun.khondang.kana))])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("ชื่อไทย :")]),
                _c("td", [_vm._v(_vm._s(_vm.khomun.khondang.th))])
              ]),
              _vm._v(" "),
              _vm.khomun.khondang.sngp
                ? _c("tr", [
                    _c("td", [_vm._v("วันเกิด :")]),
                    _vm._v(
                      _vm._s(_vm.dueanpi(_vm.khomun.khondang.sngp, false, true))
                    ),
                    _c("td")
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("เป็น :")]),
                _vm._v(_vm._s(_vm.khomun.khondang.dare)),
                _c("td")
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td"),
                _c("td", [
                  _c(
                    "a",
                    {
                      attrs: {
                        href:
                          "https://ja.wikipedia.org/wiki/" +
                          _vm.khomun.khondang.namae.replace(" ", ""),
                        target: "_blank"
                      }
                    },
                    [_vm._v("wikipedia")]
                  )
                ])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("มีวลีเด็ดทั้งหมด :")]),
                _c("td", [_vm._v(_vm._s(_vm.khomun.walidet.length))])
              ])
            ])
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c("riangphapwalidet", { attrs: { walidet: _vm.khomun.walidet } }),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("kropruamwalidet", { attrs: { walidet: _vm.khomun.walidet } }),
      _vm._v(" "),
      _c("v-container", {
        staticClass: "fb-like",
        attrs: {
          "data-share": "true",
          "data-width": "450",
          "data-show-faces": "true"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }