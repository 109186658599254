<template>
  <v-container>
    <a :href="'https://www.facebook.com/'+walidet.lek" target="_blank">
      <v-img :src="'https://hinaboshi.com/rup/rupprakopwalidet/'+walidet.lek+'.jpg'" max-width="540px"></v-img>
    </a>
    <v-simple-table>
      <tbody>
        <tr>
          <td class="subtitle-1" style="border: 1px #4A40FF solid;">วลีเด็ด</td>
          <td class="subtitle-1" style="border: 1px #4A40FF solid;">{{walidet.wali_nh}}</td>
        </tr>
        <tr>
          <td class="subtitle-1" style="border: 1px #4A40FF solid;">คำแปล</td>
          <td class="subtitle-1" style="border: 1px #4A40FF solid;">{{walidet.wali_th}}</td>
        </tr>
        <tr>
          <td class="subtitle-1" style="border: 1px #4A40FF solid;">romaji</td>
          <td class="subtitle-1" style="border: 1px #4A40FF solid;">{{walidet.wali_ro}}</td>
        </tr>
        <tr v-if="khomun.mode=='a'">
          <td class="subtitle-1" style="border: 1px #4A40FF solid;">จากเรื่อง</td>
          <td class="subtitle-1" style="border: 1px #4A40FF solid;" v-ripple="{ class: 'primary--text' }">
            <a :href="'/anime/'+encodeURIComponent(anime.namae)" data-method="get">{{walidet.anime_n}}
            <br>{{anime.romaji}}</a>
          </td>
        </tr>
        <tr v-if="walidet.ton">
          <td class="subtitle-1" style="border: 1px #4A40FF solid;">ตอน</td>
          <td class="subtitle-1" style="border: 1px #4A40FF solid;">{{walidet.ton}}</td>
        </tr>
        <tr v-if="khomun.mode=='g'">
          <td class="subtitle-1" style="border: 1px #4A40FF solid;">จากเกม</td>
          <td class="subtitle-1" style="border: 1px #4A40FF solid;" v-ripple="{ class: 'primary--text' }">
            <a :href="'/zenigame/'+encodeURIComponent(game.namae)" data-method="get">{{walidet.game_n}}
            <br>{{game.romaji}}</a>
          </td>
        </tr>
        <tr v-if="khomun.mode=='k'">
          <td class="subtitle-1" style="border: 1px #4A40FF solid;">กล่าวโดย</td>
          <td class="subtitle-1" style="border: 1px #4A40FF solid;" v-ripple="{ class: 'primary--text' }">
            <a :href="'/khondang/'+khondang.namae" data-method="get">
            {{khondang.th}}
            <br>{{khondang.namae}}</a>
          </td>
        </tr>
        <tr v-if="'ag'.includes(khomun.mode)&&doi">
          <td class="subtitle-1" style="border: 1px #4A40FF solid;">กล่าวโดย</td>
          <td class="subtitle-1" style="border: 1px #4A40FF solid;" v-ripple="{ class: 'primary--text' }">
            <a :href="'/tualakhon/'+doi.id" data-method="get">{{doi.th}}
            <br>{{doi.namae}}
            <span v-if="doi.hoka"><br>{{doi.hoka}}</span></a>
          </td>
        </tr>
        <tr v-if="'ag'.includes(khomun.mode)&&seiyuu">
          <td class="subtitle-1" style="border: 1px #4A40FF solid;">พากย์โดย</td>
          <td class="subtitle-1" style="border: 1px #4A40FF solid;" v-ripple="{ class: 'primary--text' }">
            <a :href="'/seiyuu/'+seiyuu.namae" data-method="get">{{seiyuu.th}}
            <br>{{seiyuu.namae}}</a>
          </td>
        </tr>
        <tr>
          <td class="subtitle-1" style="border: 1px #4A40FF solid;"></td>
          <td class="subtitle-1" style="border: 1px #4A40FF solid;" v-ripple="{ class: 'primary--text' }"><a :href="'https://www.facebook.com/'+walidet.lek">ดูในหน้า facebook</a></td>
        </tr>
      </tbody>
    </v-simple-table>
    <v-container class="fb-like" data-share="true" data-width="450" data-show-faces="true"></v-container>
    <div align="left">
      *บางวลีเด็ดอาจมีรายละเอียดเพิ่มเติมในหน้า facebook<br>
      *คลิกที่ชื่อบุคคลเพื่อดูวลีเด็ดจากคนเดียวกัน 
    </div>
    <div class="title">
      <a :href="'/walidet/'+khomun.lek_w_kon" data-method="get">《ดูวลีเด็ดก่อนหน้า</a> 〜〜〜
      <a :href="'/walidet/'+khomun.lek_w_lang" data-method="get">ดูวลีเด็ดถัดไป》</a>
    </div>
    <v-btn class="title" :href="'/walidet/'+khomun.lek_w_sum" data-method="get">สุ่มวลีเด็ดใหม่</v-btn>
  </v-container>
</template>

<script>
export default {
  props: {
    khomun: Object,
  },
  data(){
    return {
      walidet: this.khomun.khomunwalidet, // ข้อมูลของตัววลีเด็ด
      anime: this.khomun.anime, // อนิเมะ
      game: this.khomun.game, // เกม
      khondang: this.khomun.khondang, //คนดัง
      doi: this.khomun.doi, // ตัวละครที่พูด
      seiyuu: this.khomun.seiyuu // นักพากย์
    }
  },
}
</script>