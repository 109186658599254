var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.miphakto
        ? _c("div", [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.ruam_phakto,
                  expression: "ruam_phakto"
                }
              ],
              attrs: { type: "checkbox" },
              domProps: {
                checked: Array.isArray(_vm.ruam_phakto)
                  ? _vm._i(_vm.ruam_phakto, null) > -1
                  : _vm.ruam_phakto
              },
              on: {
                change: function($event) {
                  var $$a = _vm.ruam_phakto,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 && (_vm.ruam_phakto = $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        (_vm.ruam_phakto = $$a
                          .slice(0, $$i)
                          .concat($$a.slice($$i + 1)))
                    }
                  } else {
                    _vm.ruam_phakto = $$c
                  }
                }
              }
            }),
            _vm._v(" รวมภาคต่อ")
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.walidet_thimi, function(w, i) {
        return _c(
          "v-card",
          {
            staticClass: "d-inline-flex flex-column",
            staticStyle: { border: "solid #25b069 4px", margin: "5px" },
            attrs: { shaped: "", elevation: "10", color: "#c2ebed" }
          },
          [
            _c("div", { attrs: { align: "right" } }, [
              _vm._v("~" + _vm._s(i + 1) + "~")
            ]),
            _vm._v(" "),
            _c("v-container", { attrs: { align: "left" } }, [
              _c("table", [
                _c("tr", [
                  _c(
                    "td",
                    {
                      staticClass: "font-weight-bold",
                      staticStyle: { width: "80px" }
                    },
                    [_vm._v("ภาษาญี่ปุ่น"), _c("v-divider")],
                    1
                  ),
                  _c("td", [_vm._v(_vm._s(w.wali_nh)), _c("v-divider")], 1)
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c(
                    "td",
                    { staticClass: "font-weight-bold" },
                    [_vm._v("คำแปลไทย"), _c("v-divider")],
                    1
                  ),
                  _c("td", [_vm._v(_vm._s(w.wali_th)), _c("v-divider")], 1)
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td"),
                  _c("td", [
                    _c(
                      "a",
                      {
                        attrs: {
                          href: "/walidet/" + w.lek,
                          "data-method": "get"
                        }
                      },
                      [_vm._v("คลิกเพื่อดูรายละเอียดทั้งหมด")]
                    )
                  ])
                ])
              ])
            ])
          ],
          1
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }