<template>
  <v-container>
    <div class="display-1">รวมคำแปลวลีเด็ดจากญี่ปุ่น</div>
    <div align="left">
      ขณะนี้มีวลีเด็ดรวมทั้งหมด {{khomun.n_walidet}} วลีเด็ด<br>
      <br>
      {{khomun.n_walidetanime}} วลีเด็ด จากอนิเมะ {{khomun.n_anime}} เรื่อง<br>
      <karuse :phap="khomun.walidetanime_sum_ao"></karuse>
      <br>
      {{khomun.n_walidetgame}} วลีเด็ด จากเกม {{khomun.n_game}} เกม<br>
      <karuse :phap="khomun.walidetgame_sum_ao"></karuse>
      <br>
      {{khomun.n_walidetkhondang}} วลีเด็ด จากคนดัง {{khomun.n_khondang}} คน<br>
      <karuse :phap="khomun.walidetkhondang_sum_ao"></karuse>
      <br>
      ข้อมูลนักพากย์ {{khomun.n_seiyuu}} คน<br>
      <br>
      เพิ่มฐานข้อมูลครั้งล่าสุด {{khomun.sai_khomun_lasut_muea}}<br>
      <br>
      ปรับปรุงเว็บครั้งล่าสุด {{khomun.prapprung_web_lasut_muea}}<br>
      <br>
      เว็บนี้เป็นที่เก็บรวบรวมวลีเด็ดของเพจ <a href="https://www.facebook.com/Animankyafu" target="_blank">https://www.facebook.com/Animankyafu</a><br>
      <br>
      <iframe src="//www.facebook.com/plugins/likebox.php?href=https%3A%2F%2Fwww.facebook.com%2Fanimankyafu&amp;width=520&amp;height=240&amp;colorscheme=light&amp;show_faces=true&amp;header=true&amp;stream=false&amp;show_border=true" scrolling="no" frameborder="0" style="border:none; overflow:hidden; width:500px; height:240px;" allowTransparency="true"></iframe><br>
      <div class="fb-like" data-share="true" data-width="450" data-show-faces="true"></div>
    </div>

  </v-container>
</template>

<script>
import karuse from './karuse.vue';

export default {
  components: {
    karuse
  },
  props: {
    khomun: Object,
  },
  data(){
    return {
      
    }
  },
  computed: {
    
  }
}
</script>