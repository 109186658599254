<template>
  <v-container>
    <div class="display-1 font-weight-bold"><u>เกี่ยวกับเว็บนี้</u></div>
    <br>
    <div align="left">
    เว็บนี้เป็นที่เก็บรวบรวมวลีเด็ดของแฟนเพจ <a href="https://www.facebook.com/Animankyafu" target="_blank">https://www.facebook.com/Animankyafu</a> เพื่อเก็บเรียบเรียงข้อมูลให้เป็นระเบียบและสามารถค้นได้ง่าย<br>
    <br>
    หวังเป็นอย่างยิ่งว่าเว็บนี้จะเป็นประโยชน์สำหรับผู้ที่สนใจญี่ปุ่นทุกคน<br>
    <br>
    ข้อมูลแบ่งโดยหลัก ๆ เป็น 3 ส่วนคือ<a href="/walidetthangmot/anime" data-method="get">วลีเด็ดจากอนิเมะ</a>, <a href="/walidetthangmot/anime" data-method="get">วลีเด็ดจากเกม</a> และ <a href="/walidetthangmot/anime" data-method="get">วลีเด็ดจากคนดังชาวญี่ปุ่น</a> ทั้งหมดนี้ลงเอาไว้ในแฟนเพจด้วย แล้วก็เอามาลงในนี้อีกที<br>
    <br>
    ส่วนวลีเด็ดจำพวกอื่นเช่นจากมังงะหรือตามเว็บต่างๆยังมีไม่มากจึงไม่ได้รวบรวมลงเว็บ<br>
    <br>
    ในเว็บนี้มีข้อมูลบางส่วนที่ใส่เพิ่มเติมมากขึ้นกว่าจากในแฟนเพจ เช่น ข้อมูลนักพากย์ สามารถค้นวลีเด็ดอนิเมะและเกมจากชื่อนักพากย์ที่พากย์ตัวละครที่พูดวลีเด็ดได้ >> <a href="/seiyuuthangmot" data-method="get">วลีเด็ดจากอนิเมะและเกมแบ่งตามนักพากย์</a><br>
    <br>
    แฟนเพจถูกสร้างขึ้นในปี 2013 ดังนั้นวลีเด็ดส่วนใหญ่จึงมาจากปีนั้นเป็นต้นมา ปีเก่าๆมีอยู่น้อย<br>
    <br>
    หากมีข้อสงสัยหรือพบข้อมูลผิดพลาดโปรดติดต่อผู้ดูแลแฟนเพจผ่านทาง facebook<br>
    <br>
    และได้รับความช่วยเหลือจาก //@etc (หนึ่งในผู้ดูแลแฟนเพจ ผู้ก่อตั้งแฟนเพจ)<br>
    <br>
    </div>
    <br>
    <div class="title" align="center">บันทึกเหตุการณ์ที่สำคัญเกี่ยวกับเว็บนี้</div>
    <table style="vertical-align: top">
      <tr>
        <td>-- 18/03/2013 --<br></td>
        <td style="padding: 20px">
          เปิดตัวแฟนเพจรวมคำแปลวลีเด็ด<br>
          >> <a href="https://www.facebook.com/Animankyafu" target="_blank">https://www.facebook.com/Animankyafu</a>
        </td>
      </tr>
      <tr>
        <td>-- 17/11/2015 --<br></td>
        <td style="padding: 20px">
          เปิดตัวเว็บครั้งแรกที่ https://animankyafu.hol.es<br>
          >> <a href="https://phyblas.hinaboshi.com/20151117" target="_blank">https://phyblas.hinaboshi.com/20151117</a>
        </td>
      </tr>
      <tr>
        <td>-- 13/08/2016 --</td>
        <td style="padding: 20px">
          เว็บ https://animankyafu.hol.es ได้ถูกปิดลง
        </td>
      </tr>
      <tr>
        <td>-- 20/08/2016 --<br></td>
        <td style="padding: 20px">
          ย้ายเว็บมายัง http://hinaboshi.com<br>
          >> <a href="https://phyblas.hinaboshi.com/20160820" target="_blank">https://phyblas.hinaboshi.com/20160820</a>
        </td>
      </tr>
      <tr>
        <td>-- 24/06/2017 --</td>
        <td style="padding: 20px">
          เพิ่มระบบ meta-tag ลงไปให้มีเนื้อหาแสดงเวลาแชร์ลง facebook<br>
        </td>
      </tr>
      <tr>
        <td>-- 10/04/2020 --</td>
        <td style="padding: 20px">
          ปรับปรุงเว็บใหม่ให้กลายเป็น responsive และ material design โดยใช้ vuetify<br>
          >> <a href="https://phyblas.hinaboshi.com/20200411" target="_blank">https://phyblas.hinaboshi.com/20200411</a>
        </td>
      </tr>
    </table>
  </v-container>
</template>

<script>
export default {
  props: {
    khomun: Object,
  },
  data(){
    return {
      
    }
  },
}
</script>