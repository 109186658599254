var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    { staticStyle: { background: "#fee9da" } },
    [
      _c("nabon"),
      _vm._v(" "),
      _c(
        "v-content",
        { attrs: { app: "" } },
        [
          _c(
            "v-container",
            [
              _c(
                "v-card",
                {
                  staticStyle: { border: "3px #4a40ff solid" },
                  attrs: { elevation: "24", color: "#cdfffe", align: "center" }
                },
                [_c("naweb", { attrs: { khomun: _vm.khomun } })],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }